<div class="main-container">
	<ng-container *ngIf="publicClass">
		<button mat-button color="primary-outline" [routerLink]="['/login']">
			<mat-icon>arrow_back</mat-icon>
			Back to login
		</button>
	</ng-container>
	<h2>How can we help you?</h2>
	<ng-container *ngIf="groups">
		<mat-list>
			<mat-list-item *ngFor="let group of groups" [routerLink]="[(publicClass ? '/public' : '') + '/knowledge-base/' + group.url]">
				<mat-icon mat-list-icon><i class="fa {{ group.icon}} fa-fw"></i></mat-icon>
				<h4 mat-line>{{ group.title }}</h4>
				<p mat-line>{{ group.description }}</p>
				<mat-icon>chevron_right</mat-icon>
			</mat-list-item>
		</mat-list>
	</ng-container>
	<ng-container *ngIf="!groups">
		<p>There are no articles yet, we apologize for the inconvenience.</p>
	</ng-container>
</div>
<div class="support-bottom">
	<h3>Get in touch</h3>
	<a class="support-icon" href="tel:0365490468">
		<mat-icon>phone</mat-icon>
		<p>036 549 04 68</p>
	</a>
	<a class="support-icon" href="mailto:nl.appsupport.fss@kiwa.com">
		<mat-icon>email</mat-icon>
		<p>nl.appsupport.fss@kiwa.com</p>
	</a>
</div>
