<dl>
	<dt>{{ 'security.two-factor-auth.status' | translate }}</dt>
	<dd *ngIf="status">{{ 'security.two-factor-auth.enabled' | translate }}</dd>
	<dd *ngIf="!status">{{ 'security.two-factor-auth.disabled' | translate }}</dd>
</dl>
<ng-container *ngIf="recoveryCodes">
	<p>You have successfully activated two-factor authentication. You’ll need to enter the code once per month. But more importantly, do NOT lose access to your authenticator app or recovery codes that are provided here.</p>
</ng-container>
<ng-container *ngIf="status && recoveryCodes">
	<p>{{ 'security.two-factor-auth.please-copy-and-store-codes' | translate }}</p>
	<dl>
		<dt>{{ 'security.two-factor-auth.recovery-codes' | translate }}</dt>
		<dd [innerHTML]="'<pre>' + recoveryCodes + '</pre>'"></dd>
	</dl>
	<p><strong>{{ 'security.two-factor-auth.codes-wont-be-shown-again' | translate }}</strong></p>
</ng-container>
<ng-container *ngIf="status && !recoveryCodes">
	<dl>
		<dt>{{ 'security.two-factor-auth.recovery-codes' | translate }}</dt>
		<dd>{{ 'security.two-factor-auth.recovery-codes-hidden' | translate }}</dd>
	</dl>
</ng-container>
<div *ngIf="status === null" class="alert alert-danger" fxFlex fxLayoutAlign="center" fxLayout="row">
	<h2>{{ 'security.two-factor-auth.unknown-status' | translate }}</h2>
</div>
<button *ngIf="!status" mat-button color="primary" [routerLink]="['/my-account/two-factor-authentication/enable']">{{ 'security.two-factor-auth.action-primary' | translate }}</button>
<button *ngIf="status" mat-button color="primary-outline" [routerLink]="['/my-account/two-factor-authentication/disable']">{{ 'security.two-factor-auth.action-secondary' | translate }}</button>
<button *ngIf="status" mat-button color="primary" [routerLink]="['/my-account/two-factor-authentication/regenerate-recovery-codes']">{{ 'security.two-factor-auth.sub-action' | translate }}</button>
