var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spread = (this && this.__spread) || function () {
    for (var ar = [], i = 0; i < arguments.length; i++) ar = ar.concat(__read(arguments[i]));
    return ar;
};
import { EventEmitter } from '@angular/core';
import { ReportsService } from '../../../../_services/reports.service';
var ReportsPalletReportsListComponent = /** @class */ (function () {
    function ReportsPalletReportsListComponent(reportsService) {
        this.reportsService = reportsService;
        this.routerTransition = true;
        this.reportId = null;
        this.palletOptions = [];
        this.reportHallways = new EventEmitter();
        this.hallways = [];
        this.palletReports = [];
    }
    ReportsPalletReportsListComponent.prototype.ngOnInit = function () {
        this.getPalletReports();
    };
    ReportsPalletReportsListComponent.prototype.getPalletReports = function () {
        var _this = this;
        this.palletReports = [];
        this.hallways = [];
        this.reportsService.getPalletReports(this.reportId).then(function (data) {
            if (typeof data !== 'undefined') {
                data.forEach(function (d) {
                    var _a;
                    var report = d;
                    report['palletOptions'] = [];
                    if (typeof d.pallet_option_ids !== 'undefined') {
                        d.pallet_option_ids.forEach(function (po_id) {
                            var result = _this.palletOptions.find(function (po) { return po.id === po_id; });
                            if (typeof result !== 'undefined') {
                                report['palletOptions'].push(result.option_name);
                            }
                        });
                    }
                    _this.palletReports.push(report);
                    if (typeof d.hallways !== 'undefined') {
                        if (d.hallways !== null) {
                            (_a = _this.hallways).push.apply(_a, __spread(d.hallways));
                        }
                    }
                });
                _this.reportHallways.emit(_this.hallways);
            }
        });
    };
    ReportsPalletReportsListComponent.prototype.deletePalletReport = function (report_id, pallet_report_id) {
        var _this = this;
        this.reportsService.deletePalletReport(report_id, pallet_report_id).then(function () {
            _this.getPalletReports();
        });
    };
    return ReportsPalletReportsListComponent;
}());
export { ReportsPalletReportsListComponent };
