<button mat-button color="primary" [routerLink]="['/reports/' + report_id + '/buildings/create']">Add building / area</button>
<ng-container *ngIf="smallWidget === false">
	<button mat-button color="primary-outline" [routerLink]="['/reports/' + report_id + '/view']">View inspection</button>
	<app-search-element (formSubmittedEvent)="search($event)"></app-search-element>
</ng-container>
<mat-nav-list *ngIf="reportBuildings.length" class="mat-nav-list-with-border">
	<div *ngIf="!smallWidget" infiniteScroll infiniteScrollDistance="1" (scrolled)="getReportBuildings()"></div>
	<mat-list-item *ngFor="let reportBuilding of reportBuildings">
		<div class="mat-list-content" [routerLink]="['/reports/' + report_id + '/buildings/' + reportBuilding.id + '/reports']">
			<h4 matLine>{{ reportBuilding.name }}</h4>
		</div>
		<ng-container *ngIf="reportBuilding.color">
			<div class="tag" [style.background]="reportBuilding.color"></div>
		</ng-container>
		<ng-container *ngIf="reportBuilding.is_resolved">
			<mat-icon class="is_resolved">check_circle_outline</mat-icon>
		</ng-container>
		<button mat-icon-button color="white-no-border" (click)="deleteReportBuilding(report_id, reportBuilding.id)">
			<mat-icon>delete</mat-icon>
		</button>
	</mat-list-item>
	<a mat-list-item *ngIf="smallWidget === true && totalItems > 5" [routerLink]="['/reports/' + report_id + '/buildings']" class="mat-list-item-more">
		<span matLine>More ...</span>
		<button mat-icon-button>
			<mat-icon>chevron_right</mat-icon>
		</button>
	</a>
</mat-nav-list>
<div *ngIf="reportBuildings.length === 0">
	<br>No buildings / areas found.
</div>
