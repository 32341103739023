import { Routes } from '@angular/router';
import { AdminComponent } from './core/admin/admin.component';
import { LoginComponent } from './core/login/login/login.component';
import { ForgotPasswordComponent } from './core/login/forgot-password/forgot-password.component';
import { AuthGuard } from './_services/authguard.service';
import { ChangePasswordComponent } from './core/security/change-password/change-password.component';
import { environment } from '../environments/environment';
import { PageNotFoundComponent } from './core/page-not-found/page-not-found.component';
import { PageForbiddenComponent } from './core/page-forbidden/page-forbidden.component';
import { DashboardComponent } from './webapp/dashboard/dashboard.component';
import { ReportsRouterComponent } from './webapp/reports/reports-router/reports-router.component';
import { ReportsListComponent } from './webapp/reports/reports-list/reports-list.component';
import { ReportsCreateComponent } from './webapp/reports/reports-create/reports-create.component';
import { ReportsEditComponent } from './webapp/reports/reports-edit/reports-edit.component';
import { ReportsAttachmentsCreateComponent } from './webapp/reports/reports-attachments/reports-attachments-create/reports-attachments-create.component';
import { ReportsAttachmentsListComponent } from './webapp/reports/reports-attachments/reports-attachments-list/reports-attachments-list.component';
import { ReportsBuildingsListComponent } from './webapp/reports/reports-buildings/reports-buildings-list/reports-buildings-list.component';
import { ReportsBuildingsCreateComponent } from './webapp/reports/reports-buildings/reports-buildings-create/reports-buildings-create.component';
import { ReportsBuildingsEditComponent } from './webapp/reports/reports-buildings/reports-buildings-edit/reports-buildings-edit.component';
import { ReportsBuildingsReportsListComponent } from './webapp/reports/reports-buildings/reports-buildings-reports/reports-buildings-reports-list/reports-buildings-reports-list.component';
import { ReportsBuildingsReportsCreateComponent } from './webapp/reports/reports-buildings/reports-buildings-reports/reports-buildings-reports-create/reports-buildings-reports-create.component';
import { ReportsBuildingsReportsEditComponent } from './webapp/reports/reports-buildings/reports-buildings-reports/reports-buildings-reports-edit/reports-buildings-reports-edit.component';
import { ReportsCommentsCreateComponent } from './webapp/reports/reports-comments/reports-comments-create/reports-comments-create.component';
import { ReportsCommentsEditComponent } from './webapp/reports/reports-comments/reports-comments-edit/reports-comments-edit.component';
import { ReportsCommentsListComponent } from './webapp/reports/reports-comments/reports-comments-list/reports-comments-list.component';
import { ReportsViewComponent } from './webapp/reports/reports-view/reports-view.component';
import { ReportsSendToEmailComponent } from './webapp/reports/reports-send-to-client/reports-send-to-email.component';
import { KnowledgeBaseComponent } from './webapp/knowledge-base/knowledge-base/knowledge-base.component';
import { KVVMReportsRouterComponent } from './webapp/kvvm-reports/reports-router/reports-router.component';
import { KVVMReportsListComponent } from './webapp/kvvm-reports/reports-list/reports-list.component';
import { KVVMReportsViewComponent } from './webapp/kvvm-reports/reports-view/reports-view.component';
import { KVVMReportsAttachmentsListComponent } from './webapp/kvvm-reports/reports-attachments-list/reports-attachments-list.component';
import { KVVMReportsCommentsListComponent } from './webapp/kvvm-reports/reports-comments-list/reports-comments-list.component';
import { KVVMReportsBuildingsListComponent } from './webapp/kvvm-reports/reports-buildings/reports-buildings-list/reports-buildings-list.component';
import { KVVMReportsBuildingsReportsListComponent } from './webapp/kvvm-reports/reports-buildings/reports-buildings-reports/reports-buildings-reports-list/reports-buildings-reports-list.component';
import { KVVMReportsBuildingsReportsViewComponent } from './webapp/kvvm-reports/reports-buildings/reports-buildings-reports/reports-buildings-reports-view/reports-buildings-reports-view.component';
import { KnowledgeBaseArticlesComponent } from './webapp/knowledge-base/knowledge-base-articles/knowledge-base-articles.component';
import { KnowledgeBaseRouterComponent } from './webapp/knowledge-base/knowledge-base-router/knowledge-base-router.component';
import { KnowledgeBaseArticleComponent } from './webapp/knowledge-base/knowledge-base-article/knowledge-base-article.component';
import { LoginTwoFactorAuthenticationComponent } from './core/login/login-two-factor-authentication/login-two-factor-authentication.component';
import { LoginWithRecoveryCodeComponent } from './core/login/login-with-recovery-code/login-with-recovery-code.component';
import { RecoverLostPasswordComponent } from './core/login/recover-lost-password/recover-lost-password.component';
import { MyAccountRouterComponent } from './core/security/my-account-router/my-account-router.component';
import { MyAccountComponent } from './core/security/my-account/my-account.component';
import { TwoFactorAuthenticationComponent } from './core/security/two-factor-authentication/two-factor-authentication.component';
import { TwoFactorAuthenticationEnableComponent } from './core/security/two-factor-authentication-enable/two-factor-authentication-enable.component';
import { TwoFactorAuthenticationDisableComponent } from './core/security/two-factor-authentication-disable/two-factor-authentication-disable.component';
import { TwoFactorAuthenticationRegenerateRecoveryCodesComponent } from './core/security/two-factor-authentication-regenerate-recovery-codes/two-factor-authentication-regenerate-recovery-codes.component';
import { ReportsCompliancesListComponent } from './webapp/reports/reports-compliances/reports-compliances-list/reports-compliances-list.component';
import { ReportsCompliancesViewComponent } from './webapp/reports/reports-compliances/reports-compliances-view/reports-compliances-view.component';
import { ReportsPalletReportsCreateComponent } from './webapp/reports/reports-pallet-reports/reports-pallet-reports-create/reports-pallet-reports-create.component';
import { ReportsPalletReportsEditComponent } from './webapp/reports/reports-pallet-reports/reports-pallet-reports-edit/reports-pallet-reports-edit.component';
var ɵ0 = {
    title: 'auth.login.page-title'
}, ɵ1 = {
    title: 'Two Factor Authentication Login'
}, ɵ2 = {
    title: 'Two Factor Recovery Code Login'
}, ɵ3 = {
    title: 'Forgotten password'
}, ɵ4 = {
    title: 'Recover lost password'
}, ɵ5 = {
    title: 'Knowledge base'
}, ɵ6 = {
    title: 'Knowledge base'
}, ɵ7 = {
    title: 'Knowledge base'
}, ɵ8 = {
    title: 'Knowledge base'
}, ɵ9 = {
    title: environment.webappName
}, ɵ10 = {
    title: 'Dashboard'
}, ɵ11 = {
    title: 'security.my-account.page-title'
}, ɵ12 = {
    title: 'security.my-account.page-title'
}, ɵ13 = {
    title: 'security.two-factor-auth.page-title'
}, ɵ14 = {
    title: 'security.two-factor-auth-enable.page-title'
}, ɵ15 = {
    title: 'security.two-factor-auth-disable.page-title'
}, ɵ16 = {
    title: 'security.two-factor-auth-recovery-codes.page-title'
}, ɵ17 = {
    title: 'security.change-password.page-title'
}, ɵ18 = {
    title: 'Reports'
}, ɵ19 = {
    title: 'Create new report'
}, ɵ20 = {
    title: 'Edit report'
}, ɵ21 = {
    title: 'View report'
}, ɵ22 = {
    title: 'Report attachments'
}, ɵ23 = {
    title: 'Add report attachment'
}, ɵ24 = {
    title: 'Report comments'
}, ɵ25 = {
    title: 'Add report comment'
}, ɵ26 = {
    title: 'Edit report comment'
}, ɵ27 = {
    title: 'Report buildings'
}, ɵ28 = {
    title: 'Create report building'
}, ɵ29 = {
    title: 'Edit report building'
}, ɵ30 = {
    title: 'Building reports'
}, ɵ31 = {
    title: 'Create building report'
}, ɵ32 = {
    title: 'Edit building report'
}, ɵ33 = {
    title: 'Send report to email'
}, ɵ34 = {
    title: 'Compliance checklist'
}, ɵ35 = {
    title: 'View compliance checklist'
}, ɵ36 = {
    title: 'View compliance checklist'
}, ɵ37 = {
    title: 'Create pallet report'
}, ɵ38 = {
    title: 'Edit pallet report'
}, ɵ39 = {
    title: 'Reports'
}, ɵ40 = {
    title: 'View report'
}, ɵ41 = {
    title: 'Report attachments'
}, ɵ42 = {
    title: 'Report comments'
}, ɵ43 = {
    title: 'Report buildings'
}, ɵ44 = {
    title: 'Building reports'
}, ɵ45 = {
    title: 'View building report'
}, ɵ46 = {
    title: 'Knowledge base'
}, ɵ47 = {
    title: 'Knowledge base'
}, ɵ48 = {
    title: 'Knowledge base'
}, ɵ49 = {
    title: 'Knowledge base'
}, ɵ50 = {
    title: 'Forbidden'
}, ɵ51 = {
    title: 'Not found'
};
var routes = [
    {
        path: 'login',
        component: LoginComponent,
        data: ɵ0
    },
    {
        path: 'two-factor-authentication',
        component: LoginTwoFactorAuthenticationComponent,
        data: ɵ1
    },
    {
        path: 'login-with-recovery-code',
        component: LoginWithRecoveryCodeComponent,
        data: ɵ2
    },
    {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
        data: ɵ3
    },
    {
        path: 'recover-lost-password/:recovery_token',
        component: RecoverLostPasswordComponent,
        data: ɵ4
    },
    {
        path: 'public/knowledge-base',
        component: KnowledgeBaseRouterComponent,
        data: ɵ5,
        children: [
            {
                path: '',
                component: KnowledgeBaseComponent,
                data: ɵ6
            },
            {
                path: ':group_url',
                component: KnowledgeBaseArticlesComponent,
                data: ɵ7
            },
            {
                path: ':group_url/:article_url',
                component: KnowledgeBaseArticleComponent,
                data: ɵ8
            }
        ]
    },
    {
        path: '',
        component: AdminComponent,
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        data: ɵ9,
        children: [
            {
                path: '',
                component: DashboardComponent,
                data: ɵ10
            },
            {
                path: 'my-account',
                component: MyAccountRouterComponent,
                data: ɵ11,
                children: [
                    {
                        path: '',
                        component: MyAccountComponent,
                        data: ɵ12
                    },
                    {
                        path: 'two-factor-authentication',
                        component: TwoFactorAuthenticationComponent,
                        data: ɵ13
                    },
                    {
                        path: 'two-factor-authentication/enable',
                        component: TwoFactorAuthenticationEnableComponent,
                        data: ɵ14
                    },
                    {
                        path: 'two-factor-authentication/disable',
                        component: TwoFactorAuthenticationDisableComponent,
                        data: ɵ15
                    },
                    {
                        path: 'two-factor-authentication/regenerate-recovery-codes',
                        component: TwoFactorAuthenticationRegenerateRecoveryCodesComponent,
                        data: ɵ16
                    }
                ]
            },
            {
                path: 'change-password',
                component: ChangePasswordComponent,
                data: ɵ17
            },
            {
                path: 'reports',
                component: ReportsRouterComponent,
                children: [
                    {
                        path: '',
                        component: ReportsListComponent,
                        data: ɵ18
                    },
                    {
                        path: 'create',
                        component: ReportsCreateComponent,
                        data: ɵ19
                    },
                    {
                        path: ':report_id/edit',
                        component: ReportsEditComponent,
                        data: ɵ20
                    },
                    {
                        path: ':report_id/view',
                        component: ReportsViewComponent,
                        data: ɵ21
                    },
                    {
                        path: ':report_id/attachments',
                        component: ReportsAttachmentsListComponent,
                        data: ɵ22
                    },
                    {
                        path: ':report_id/attachments/create',
                        component: ReportsAttachmentsCreateComponent,
                        data: ɵ23
                    },
                    {
                        path: ':report_id/comments',
                        component: ReportsCommentsListComponent,
                        data: ɵ24
                    },
                    {
                        path: ':report_id/comments/create',
                        component: ReportsCommentsCreateComponent,
                        data: ɵ25
                    },
                    {
                        path: ':report_id/comments/:comment_id/edit',
                        component: ReportsCommentsEditComponent,
                        data: ɵ26
                    },
                    {
                        path: ':report_id/buildings',
                        component: ReportsBuildingsListComponent,
                        data: ɵ27
                    },
                    {
                        path: ':report_id/buildings/create',
                        component: ReportsBuildingsCreateComponent,
                        data: ɵ28
                    },
                    {
                        path: ':report_id/buildings/:building_id/edit',
                        component: ReportsBuildingsEditComponent,
                        data: ɵ29
                    },
                    {
                        path: ':report_id/buildings/:building_id/reports',
                        component: ReportsBuildingsReportsListComponent,
                        data: ɵ30
                    },
                    {
                        path: ':report_id/buildings/:building_id/reports/create',
                        component: ReportsBuildingsReportsCreateComponent,
                        data: ɵ31
                    },
                    {
                        path: ':report_id/buildings/:building_id/reports/:building_report_id/edit',
                        component: ReportsBuildingsReportsEditComponent,
                        data: ɵ32
                    },
                    {
                        path: ':report_id/send-to-email',
                        component: ReportsSendToEmailComponent,
                        data: ɵ33
                    },
                    {
                        path: ':report_id/compliances',
                        component: ReportsCompliancesListComponent,
                        data: ɵ34
                    },
                    {
                        path: ':report_id/compliances/:weekday',
                        component: ReportsCompliancesViewComponent,
                        data: ɵ35
                    },
                    {
                        path: ':report_id/compliances/:weekday/:compliance_id',
                        component: ReportsCompliancesViewComponent,
                        data: ɵ36
                    },
                    {
                        path: ':report_id/pallet-reports/create',
                        component: ReportsPalletReportsCreateComponent,
                        data: ɵ37
                    },
                    {
                        path: ':report_id/pallet-reports/:pallet_report_id',
                        component: ReportsPalletReportsEditComponent,
                        data: ɵ38
                    }
                ]
            },
            {
                path: 'kvvm-reports',
                component: KVVMReportsRouterComponent,
                children: [
                    {
                        path: '',
                        component: KVVMReportsListComponent,
                        data: ɵ39
                    },
                    {
                        path: ':report_id/view',
                        component: KVVMReportsViewComponent,
                        data: ɵ40
                    },
                    {
                        path: ':report_id/attachments',
                        component: KVVMReportsAttachmentsListComponent,
                        data: ɵ41
                    },
                    {
                        path: ':report_id/comments',
                        component: KVVMReportsCommentsListComponent,
                        data: ɵ42
                    },
                    {
                        path: ':report_id/buildings',
                        component: KVVMReportsBuildingsListComponent,
                        data: ɵ43
                    },
                    {
                        path: ':report_id/buildings/:building_id/reports',
                        component: KVVMReportsBuildingsReportsListComponent,
                        data: ɵ44
                    },
                    {
                        path: ':report_id/buildings/:building_id/reports/:building_report_id/view',
                        component: KVVMReportsBuildingsReportsViewComponent,
                        data: ɵ45
                    }
                ]
            },
            {
                path: 'knowledge-base',
                component: KnowledgeBaseRouterComponent,
                data: ɵ46,
                children: [
                    {
                        path: '',
                        component: KnowledgeBaseComponent,
                        data: ɵ47
                    },
                    {
                        path: ':group_url',
                        component: KnowledgeBaseArticlesComponent,
                        data: ɵ48
                    },
                    {
                        path: ':group_url/:article_url',
                        component: KnowledgeBaseArticleComponent,
                        data: ɵ49
                    }
                ]
            },
            {
                path: '403',
                component: PageForbiddenComponent,
                data: ɵ50
            },
            {
                path: '**',
                component: PageNotFoundComponent,
                data: ɵ51
            }
        ]
    }
];
var RoutingModule = /** @class */ (function () {
    function RoutingModule() {
    }
    return RoutingModule;
}());
export { RoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14, ɵ15, ɵ16, ɵ17, ɵ18, ɵ19, ɵ20, ɵ21, ɵ22, ɵ23, ɵ24, ɵ25, ɵ26, ɵ27, ɵ28, ɵ29, ɵ30, ɵ31, ɵ32, ɵ33, ɵ34, ɵ35, ɵ36, ɵ37, ɵ38, ɵ39, ɵ40, ɵ41, ɵ42, ɵ43, ɵ44, ɵ45, ɵ46, ɵ47, ɵ48, ɵ49, ɵ50, ɵ51 };
