<form [formGroup]="myProfileForm" (ngSubmit)="authenticationService.updateMyProfile(myProfileForm.value)">
	<mat-form-field>
		<input matInput placeholder="{{ 'shared.first-name' | translate }}" type="text" required formControlName="firstname">
		<mat-error *ngIf="formsService.getFieldError('firstname')">{{formsService.getFieldError('firstname')}}</mat-error>
	</mat-form-field>
	<mat-form-field>
		<input matInput placeholder="{{ 'shared.last-name' | translate }}" type="text" required formControlName="lastname">
		<mat-error *ngIf="formsService.getFieldError('lastname')">{{formsService.getFieldError('lastname')}}</mat-error>
	</mat-form-field>
	<button color="primary" mat-button [disabled]="!myProfileForm.valid">{{ 'security.my-account.action' | translate }}</button>
</form>
