import { HttpClient } from '@angular/common/http';
import { provideErrorHandler } from './_services/sentry-error-handler.service';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
export function HttpLoaderFactory(httpClient) {
    return new TranslateHttpLoader(httpClient);
}
var ɵ0 = provideErrorHandler;
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0 };
