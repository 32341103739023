<img src="/assets/images/logo.png" alt="KVVM" width="250" height="83">
<h2>{{ 'general.app-name' | translate }}</h2>
<form [formGroup]="loginForm" (ngSubmit)="authenticationService.login(loginForm.value, returnUrl)">
	<mat-form-field>
		<input title="" matInput placeholder="{{ 'shared.email' | translate }}" type="email" name="email" required formControlName="email">
		<mat-error *ngIf="formsService.getFieldError('email')">{{formsService.getFieldError('email')}}</mat-error>
	</mat-form-field>
	<mat-form-field>
		<input title="" matInput placeholder="{{ 'shared.password' | translate }}" type="password" name="password" required formControlName="password">
		<mat-error *ngIf="formsService.getFieldError('password')">{{formsService.getFieldError('password')}}</mat-error>
	</mat-form-field>
	<button mat-raised-button color="primary" [disabled]="!loginForm.valid">{{ 'auth.login.action' | translate}}</button>
</form>
<div>
	<a [routerLink]="['/forgot-password']">{{ 'auth.login.sub-action' | translate }}</a>
	|
	<a [routerLink]="['/public/knowledge-base']">{{ 'sidenav.knowledge-base' | translate}}</a>
</div>
