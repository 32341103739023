<button mat-button color="primary" [routerLink]="['/reports/' + reportId + '/pallet-reports/create']">Add pallet report</button>
<mat-nav-list *ngIf="palletReports.length" class="mat-nav-list-with-border">
	<mat-list-item *ngFor="let report of palletReports">
		<div class="mat-list-content compliance" [routerLink]="'/reports/' + reportId + '/pallet-reports/' + report.id">
			<p matLine>{{report.position_nr}} - {{report.field}} - {{report.level}}</p>
			<p matLine><ng-container *ngFor="let option of report.palletOptions"> - {{ option }}<br></ng-container></p>
			<p matLine *ngIf="report.notes">{{report.notes}}</p>
		</div>
		<button mat-icon-button color="white-no-border" [matMenuTriggerFor]="menu">
			<mat-icon>more_vert</mat-icon>
		</button>
		<mat-menu #menu="matMenu">
			<a mat-menu-item [routerLink]="'/reports/' + reportId + '/pallet-reports/' + report.id">Edit</a>
			<a mat-menu-item (click)="deletePalletReport(report.report_id, report.id)">Delete</a>
		</mat-menu>
	</mat-list-item>
</mat-nav-list>
<div *ngIf="palletReports.length === 0">
	<br>No pallet reports found.
</div>
