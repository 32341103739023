<img src="/assets/images/logo.png" alt="KVVM" width="250" height="83">
<h2>{{ 'general.app-name' | translate }}</h2>
<form [formGroup]="otpForm" (ngSubmit)="authenticationService.loginTwoFactorRecoveryCode(otpForm.value)">
	<input type="hidden" name="sessionID" value="{{ sessionId }}">
	<p>{{ 'auth.two-factor-recovery-code-login.enter-code' | translate }}</p>
	<mat-form-field>
		<input matInput placeholder="{{ 'auth.two-factor-recovery-code-login.code' | translate }}" type="text" required formControlName="code">
		<mat-error *ngIf="formService.getFieldError('code')">{{ formService.getFieldError('code') }}</mat-error>
	</mat-form-field>
	<button color="primary" mat-raised-button [disabled]="!otpForm.valid">{{ 'shared.actions.confirm' | translate }}</button>
</form>
<a [routerLink]="['/login-two-factor-authentication']">{{ 'auth.two-factor-recovery-code-login.sub-action' | translate }}</a>
