<button mat-button color="primary-outline" [routerLink]="['/kvvm-reports']">View all inspections</button>
<ng-container *ngIf="dataLoaded && !missingData.length">
	<dl *ngIf="report">
		<dt>Inspection number</dt>
		<dd>{{ report.report_nr }}</dd>
		<dt>Date created</dt>
		<dd *ngIf="report.created !== null">{{ report.created | datex:'DD-MM-YYYY' }}</dd>
		<dd *ngIf="report.created === null">-</dd>
		<dt>Date updated</dt>
		<dd *ngIf="report.modified !== null">{{ report.modified | datex:'DD-MM-YYYY' }}</dd>
		<dd *ngIf="report.modified === null">-</dd>
		<dt>Color code</dt>
		<dd><div class="tag" [style.background]="report.color"></div></dd>
	</dl>
	<div class="divider"><span>Buildings / areas</span></div>
	<app-kvvm-reports-buildings-list [smallWidget]="true" style="margin:-10px 0 0 0;"></app-kvvm-reports-buildings-list>
	<div class="divider"><span>Download</span></div>
	<div class="buttons-in-line" fxLayout="row">
		<button *ngIf="apiService.isOnline" mat-button color="primary-outline" (click)="downloadReport(report.id, 'pdf')">PDF</button>
		<button *ngIf="apiService.isOnline" mat-button color="primary-outline" (click)="downloadReport(report.id, 'xlsx')">XLS</button>
		<button *ngIf="apiService.isOnline && report.building_map" mat-button color="primary-outline" (click)="downloadReport(report.id, 'map')">Map</button>
		<button *ngIf="!apiService.isOnline" mat-button color="grey-outline" (click)="apiService.notAvailableOffline()">PDF</button>
		<button *ngIf="!apiService.isOnline" mat-button color="grey-outline" (click)="apiService.notAvailableOffline()">XLS</button>
		<button *ngIf="!apiService.isOnline && report.building_map" mat-button color="grey-outline" (click)="apiService.notAvailableOffline()">Map</button>
	</div>
	<app-kvvm-reports-comments-list [smallWidget]="true" style="margin:20px 0 0 0;"></app-kvvm-reports-comments-list>
	<app-kvvm-reports-attachments-list [smallWidget]="true" style="margin:20px 0 0 0;"></app-kvvm-reports-attachments-list>
</ng-container>
<div *ngIf="missingData.length" class="alert alert-danger" fxFlex fxLayout="row">
	<mat-icon>warning</mat-icon>
	<h2>Not all data is available online, go online to continue.</h2>
</div>
