var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs/Rx';
import { LoadingOverlayService } from './loading-overlay.service';
import { ApiService } from '../_interceptors/api.service';
import { SnackbarService } from './snackbar.service';
import { PreviousUrlService } from './previous-url.service';
import { MatDialog } from '@angular/material/dialog';
import { SimpleModalDialogComponent } from '../core/simple-modal/simple-modal.component';
import { IndexedDBService } from './indexeddb.service';
import * as moment from 'moment';
import { AuthenticationService } from './authentication.service';
import { LocalStorageService } from './local-storage.service';
var ReportsService = /** @class */ (function () {
    function ReportsService(apiService, indexedDBService, dialogService, overlayService, snackbarService, previousUrlService, authenticationService, localStorageService, router) {
        this.apiService = apiService;
        this.indexedDBService = indexedDBService;
        this.dialogService = dialogService;
        this.overlayService = overlayService;
        this.snackbarService = snackbarService;
        this.previousUrlService = previousUrlService;
        this.authenticationService = authenticationService;
        this.localStorageService = localStorageService;
        this.router = router;
        this.errors = new BehaviorSubject(null);
    }
    ReportsService.prototype.getShortcomingCategories = function () {
        var _this = this;
        return new Promise(function (resolve) {
            _this.indexedDBService.databaseReady.subscribe(function (event) {
                if (event) {
                    var collection_1 = [];
                    _this.indexedDBService.database.getAllFast(_this.indexedDBService.tableReportsCategories).then(function (categoriesDB) {
                        if (typeof categoriesDB !== 'undefined') {
                            collection_1 = categoriesDB.sort(function (a, b) { return a.sequence > b.sequence ? 1 : -1; });
                            resolve({
                                recordsTotal: collection_1.length,
                                data: collection_1
                            });
                        }
                        else {
                            _this.syncCategories().then(function (categories) {
                                collection_1 = categories.sort(function (a, b) { return a.sequence > b.sequence ? 1 : -1; });
                                if (typeof categories !== 'undefined') {
                                    resolve({
                                        recordsTotal: collection_1.length,
                                        data: collection_1
                                    });
                                }
                                else {
                                    resolve(undefined);
                                }
                            }).catch(function (error) {
                                resolve(undefined);
                                throw new Error(error);
                            });
                        }
                    }, function () {
                        _this.syncCategories().then(function (categories) {
                            if (typeof categories !== 'undefined') {
                                collection_1 = categories.sort(function (a, b) { return a.sequence > b.sequence ? 1 : -1; });
                                resolve({
                                    recordsTotal: collection_1.length,
                                    data: collection_1
                                });
                            }
                            else {
                                resolve(undefined);
                            }
                        }).catch(function (error) {
                            resolve(undefined);
                            throw new Error(error);
                        });
                    });
                }
            });
        });
    };
    ReportsService.prototype.getShortcomingSubcategories = function (category_id) {
        var _this = this;
        return new Promise(function (resolve) {
            _this.indexedDBService.databaseReady.subscribe(function (event) {
                if (event) {
                    var collection_2 = [];
                    _this.indexedDBService.database.getAllFast(_this.indexedDBService.tableReportsSubcategories).then(function (subcategoriesDB) {
                        if (typeof subcategoriesDB !== 'undefined') {
                            collection_2 = subcategoriesDB.filter(function (subcategory) { return subcategory.category_id === category_id; });
                            collection_2.sort(function (a, b) { return a.subcategory_name.toLowerCase() > b.subcategory_name.toLowerCase() ? 1 : -1; });
                            resolve({
                                recordsTotal: collection_2.length,
                                data: collection_2
                            });
                        }
                        else {
                            _this.syncSubcategories().then(function (subcategories) {
                                if (typeof subcategories !== 'undefined') {
                                    collection_2 = subcategories.filter(function (subcategory) { return subcategory.category_id === category_id; });
                                    collection_2.sort(function (a, b) { return a.subcategory_name.toLowerCase() > b.subcategory_name.toLowerCase() ? 1 : -1; });
                                    resolve({
                                        recordsTotal: collection_2.length,
                                        data: collection_2
                                    });
                                }
                                else {
                                    resolve(undefined);
                                }
                            }).catch(function (error) {
                                resolve(undefined);
                                throw new Error(error);
                            });
                        }
                    }, function () {
                        _this.syncSubcategories().then(function (subcategories) {
                            if (typeof subcategories !== 'undefined') {
                                collection_2 = subcategories.filter(function (subcategory) { return subcategory.category_id === category_id; });
                                collection_2.sort(function (a, b) { return a.subcategory_name.toLowerCase() > b.subcategory_name.toLowerCase() ? 1 : -1; });
                                resolve({
                                    recordsTotal: collection_2.length,
                                    data: collection_2
                                });
                            }
                            else {
                                resolve(undefined);
                            }
                        }).catch(function (error) {
                            resolve(undefined);
                            throw new Error(error);
                        });
                    });
                }
            });
        });
    };
    ReportsService.prototype.getShortcomingTypes = function (category_id, subcategory_id) {
        var _this = this;
        return new Promise(function (resolve) {
            _this.indexedDBService.databaseReady.subscribe(function (event) {
                if (event) {
                    var collection_3 = [];
                    _this.indexedDBService.database.getAllFast(_this.indexedDBService.tableReportsTypes).then(function (typesDB) {
                        if (typeof typesDB !== 'undefined') {
                            collection_3 = typesDB.filter(function (type) { return type.category_id === category_id && type.subcategory_id === subcategory_id; });
                            collection_3.sort(function (a, b) { return a.type_name.toLowerCase() > b.type_name.toLowerCase() ? 1 : -1; });
                            resolve({
                                recordsTotal: collection_3.length,
                                data: collection_3
                            });
                        }
                        else {
                            _this.syncTypes().then(function (types) {
                                if (typeof types !== 'undefined') {
                                    collection_3 = types.filter(function (type) { return type.category_id === category_id && type.subcategory_id === subcategory_id; });
                                    collection_3.sort(function (a, b) { return a.type_name.toLowerCase() > b.type_name.toLowerCase() ? 1 : -1; });
                                    resolve({
                                        recordsTotal: collection_3.length,
                                        data: collection_3
                                    });
                                }
                                else {
                                    resolve(undefined);
                                }
                            }).catch(function (error) {
                                resolve(undefined);
                                throw new Error(error);
                            });
                        }
                    }, function () {
                        _this.syncTypes().then(function (types) {
                            if (typeof types !== 'undefined') {
                                collection_3 = types.filter(function (type) { return type.category_id === category_id && type.subcategory_id === subcategory_id; });
                                collection_3.sort(function (a, b) { return a.type_name.toLowerCase() > b.type_name.toLowerCase() ? 1 : -1; });
                                resolve({
                                    recordsTotal: collection_3.length,
                                    data: collection_3
                                });
                            }
                            else {
                                resolve(undefined);
                            }
                        }).catch(function (error) {
                            resolve(undefined);
                            throw new Error(error);
                        });
                    });
                }
            });
        });
    };
    ReportsService.prototype.getShortcomingActions = function (category_id, subcategory_id) {
        var _this = this;
        return new Promise(function (resolve) {
            _this.indexedDBService.databaseReady.subscribe(function (event) {
                if (event) {
                    var collection_4 = [];
                    _this.indexedDBService.database.getAllFast(_this.indexedDBService.tableReportsActions).then(function (actionsDB) {
                        if (typeof actionsDB !== 'undefined') {
                            collection_4 = actionsDB.filter(function (action) { return action.category_id === category_id && action.subcategory_id === subcategory_id; });
                            collection_4.sort(function (a, b) { return a.action_name.toLowerCase() > b.action_name.toLowerCase() ? 1 : -1; });
                            resolve({
                                recordsTotal: collection_4.length,
                                data: collection_4
                            });
                        }
                        else {
                            _this.syncActions().then(function (actions) {
                                if (typeof actions !== 'undefined') {
                                    collection_4 = actions.filter(function (action) { return action.category_id === category_id && action.subcategory_id === subcategory_id; });
                                    collection_4.sort(function (a, b) { return a.action_name.toLowerCase() > b.action_name.toLowerCase() ? 1 : -1; });
                                    resolve({
                                        recordsTotal: collection_4.length,
                                        data: collection_4
                                    });
                                }
                                else {
                                    resolve(undefined);
                                }
                            }).catch(function (error) {
                                resolve(undefined);
                                throw new Error(error);
                            });
                        }
                    }, function () {
                        _this.syncActions().then(function (actions) {
                            if (typeof actions !== 'undefined') {
                                collection_4 = actions.filter(function (action) { return action.category_id === category_id && action.subcategory_id === subcategory_id; });
                                collection_4.sort(function (a, b) { return a.action_name.toLowerCase() > b.action_name.toLowerCase() ? 1 : -1; });
                                resolve({
                                    recordsTotal: collection_4.length,
                                    data: collection_4
                                });
                            }
                            else {
                                resolve(undefined);
                            }
                        }).catch(function (error) {
                            resolve(undefined);
                            throw new Error(error);
                        });
                    });
                }
            });
        });
    };
    ReportsService.prototype.getPalletOptions = function () {
        var _this = this;
        return new Promise(function (resolve) {
            _this.indexedDBService.databaseReady.subscribe(function (event) {
                if (event) {
                    var collection_5 = [];
                    _this.indexedDBService.database.getAllFast(_this.indexedDBService.tableReportsIkeaPalletOptions).then(function (palletOptionsDB) {
                        if (typeof palletOptionsDB !== 'undefined') {
                            collection_5 = palletOptionsDB.sort(function (a, b) { return a.option_name > b.option_name ? 1 : -1; });
                            resolve({
                                recordsTotal: collection_5.length,
                                data: collection_5
                            });
                        }
                        else {
                            _this.syncPalletOptions().then(function (categories) {
                                collection_5 = categories.sort(function (a, b) { return a.option_name > b.option_name ? 1 : -1; });
                                if (typeof categories !== 'undefined') {
                                    resolve({
                                        recordsTotal: collection_5.length,
                                        data: collection_5
                                    });
                                }
                                else {
                                    resolve(undefined);
                                }
                            }).catch(function (error) {
                                resolve(undefined);
                                throw new Error(error);
                            });
                        }
                    }, function () {
                        _this.syncPalletOptions().then(function (categories) {
                            if (typeof categories !== 'undefined') {
                                collection_5 = categories.sort(function (a, b) { return a.option_name > b.option_name ? 1 : -1; });
                                resolve({
                                    recordsTotal: collection_5.length,
                                    data: collection_5
                                });
                            }
                            else {
                                resolve(undefined);
                            }
                        }).catch(function (error) {
                            resolve(undefined);
                            throw new Error(error);
                        });
                    });
                }
            });
        });
    };
    ReportsService.prototype.getReports = function (formData) {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                resolve(_this.getReportsWhenOnline(formData));
            }
            else {
                resolve(_this.getReportsWhenOffline(formData));
            }
        });
    };
    ReportsService.prototype.getReportsWhenOffline = function (formData) {
        var _this = this;
        return new Promise(function (resolve) {
            _this.indexedDBService.databaseReady.subscribe(function (event) {
                if (event) {
                    var results_1 = [], collection_6 = [], startIndex_1 = 0, endIndex_1 = -1, searchString_1 = null;
                    if (formData !== null) {
                        if (typeof formData['start'] !== 'undefined' && typeof formData['length'] !== 'undefined') {
                            startIndex_1 = formData['start'];
                            endIndex_1 = formData['length'];
                            if (endIndex_1 !== -1) {
                                endIndex_1 += startIndex_1;
                            }
                        }
                        if (typeof formData['columns[0][search][value]'] !== 'undefined') {
                            if (formData['columns[0][search][value]'] !== null && formData['columns[0][search][value]'] !== '') {
                                searchString_1 = formData['columns[0][search][value]'].toLowerCase();
                            }
                        }
                    }
                    _this.indexedDBService.database.getAllFast(_this.indexedDBService.tableReports).then(function (reportsDB) {
                        if (typeof reportsDB !== 'undefined') {
                            collection_6 = reportsDB.filter(function (reports) { return typeof reports.indexedDBMethod === 'undefined' || typeof reports.indexedDBMethod !== 'undefined' && reports.indexedDBMethod !== '3-delete'; });
                            collection_6.sort(function (a, b) { return a.created > b.created ? -1 : 1; });
                            if (searchString_1 !== null) {
                                collection_6 = collection_6.filter(function (collection2) { return collection2.report_nr.toLowerCase().indexOf(searchString_1) !== -1 || collection2.company_name.toLowerCase().indexOf(searchString_1) !== -1; });
                            }
                            if (endIndex_1 !== -1) {
                                results_1 = collection_6.slice(startIndex_1, endIndex_1);
                            }
                            else {
                                results_1 = collection_6.slice();
                            }
                            resolve({
                                recordsTotal: collection_6.length,
                                data: results_1
                            });
                        }
                        else {
                            resolve(undefined);
                        }
                    }, function (error) {
                        resolve(undefined);
                        throw new Error(error);
                    });
                }
            });
        });
    };
    ReportsService.prototype.getReportsWhenOnline = function (formData) {
        var _this = this;
        if (formData === void 0) { formData = null; }
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                _this.apiService.get(_this.indexedDBService.tableReports, formData).then(function (data) {
                    if (typeof data !== 'undefined') {
                        if (typeof data.success !== 'undefined') {
                            if (data.success === true) {
                                resolve(data);
                            }
                            else {
                                resolve(undefined);
                            }
                        }
                        else {
                            resolve(undefined);
                        }
                    }
                    else {
                        resolve(undefined);
                    }
                }).catch(function (error) {
                    resolve(undefined);
                    throw new Error(error);
                });
            }
            else {
                resolve(undefined);
            }
        });
    };
    ReportsService.prototype.getReport = function (report_id) {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                resolve(_this.getReportWhenOnline(report_id));
            }
            else {
                resolve(_this.getReportWhenOffline(report_id));
            }
        });
    };
    ReportsService.prototype.getReportWhenOffline = function (report_id) {
        var _this = this;
        return new Promise(function (resolve) {
            _this.indexedDBService.databaseReady.subscribe(function (event) {
                if (event) {
                    _this.indexedDBService.database.getByKey(_this.indexedDBService.tableReports, report_id).then(function (report) {
                        if (typeof report !== 'undefined') {
                            resolve(report);
                        }
                        else {
                            resolve(undefined);
                        }
                    }, function (error) {
                        resolve(undefined);
                        throw new Error(error);
                    });
                }
            });
        });
    };
    ReportsService.prototype.getReportWhenOnline = function (report_id) {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                _this.apiService.get('reports/' + report_id).then(function (data) {
                    if (typeof data !== 'undefined') {
                        if (typeof data.success !== 'undefined') {
                            if (data.success === true) {
                                if (typeof data.data !== 'undefined') {
                                    if (typeof data.data[0] !== 'undefined') {
                                        resolve(data.data[0]);
                                    }
                                    else {
                                        resolve(undefined);
                                    }
                                }
                                else {
                                    resolve(undefined);
                                }
                            }
                            else {
                                resolve(undefined);
                            }
                        }
                        else {
                            resolve(undefined);
                        }
                    }
                    else {
                        resolve(undefined);
                    }
                }).catch(function (error) {
                    resolve(undefined);
                    throw new Error(error);
                });
            }
            else {
                resolve(undefined);
            }
        });
    };
    ReportsService.prototype.createReport = function (formData) {
        var _this = this;
        if (this.apiService.isOnline) {
            this.apiService.post(this.indexedDBService.tableReports, formData).then(function (data) {
                if (data.success === true) {
                    _this.snackbarService.success('Report saved!');
                    if (typeof data.data !== 'undefined') {
                        _this.indexedDBService.database.add(_this.indexedDBService.tableReports, data.data).then(function () {
                        }, function (error) { return new Error(error); });
                        _this.router.navigate(['/reports/' + data.data.id + '/view']).then(function () {
                        });
                    }
                    else {
                        _this.router.navigate(['/reports']).then(function () {
                        });
                    }
                }
                else if (typeof data.error !== 'undefined') {
                    _this.errors.next(data.error);
                }
                else {
                    _this.snackbarService.error('An error occurred while loading the data, please try again.');
                }
            });
        }
        else {
            var _userSubscription = this.authenticationService.user.subscribe(function (user) {
                formData['id'] = moment().valueOf();
                formData['account_user_id'] = user.id;
                formData['inspector'] = user.display_name;
                formData['company_name'] = user.company_name;
                formData['created'] = moment().valueOf();
                if (formData['type'] !== 'warehouse') {
                    formData['report_nr'] = formData['week'];
                }
                delete formData['account_user_id_filterString'];
                delete formData['client_filterString'];
                _this.indexedDBService.database.add(_this.indexedDBService.tableReports, formData).then(function () {
                    _this.snackbarService.success('Report saved!');
                    formData['indexedDBMethod'] = '1-create';
                    _this.indexedDBService.database.add(_this.indexedDBService.tableReportsPush, formData).then(function () {
                    }, function (error) { return new Error(error); });
                    _this.router.navigate(['/reports/' + formData['id'] + '/view']).then(function () {
                    });
                }, function (error) {
                    _this.snackbarService.error('An error occurred while loading the data, please try again.');
                    throw new Error(error);
                });
            });
            _userSubscription.unsubscribe();
        }
    };
    ReportsService.prototype.updateReport = function (report_id, formData) {
        var _this = this;
        if (this.apiService.isOnline) {
            this.apiService.post('reports/' + report_id, formData).then(function (data) {
                if (data.success === true) {
                    _this.snackbarService.success('Report saved!');
                    if (typeof data.data !== 'undefined') {
                        _this.indexedDBService.database.update(_this.indexedDBService.tableReports, data.data).then(function () {
                        }, function (error) { return new Error(error); });
                        _this.router.navigate(['/reports/' + report_id + '/view']).then(function () {
                        });
                    }
                    else {
                        _this.router.navigate(['/reports']).then(function () {
                        });
                    }
                }
                else if (typeof data.error !== 'undefined') {
                    _this.errors.next(data.error);
                }
                else {
                    _this.snackbarService.error('An error occurred while loading the data, please try again.');
                }
            });
        }
        else {
            this.indexedDBService.database.getByIndex(this.indexedDBService.tableReports, 'id', report_id).then(function (report) {
                delete formData['account_user_id_filterString'];
                delete formData['client_filterString'];
                formData['report_nr'] = formData['week'];
                var newReport = __assign({}, report, formData);
                newReport['updated'] = moment().valueOf();
                _this.indexedDBService.database.update(_this.indexedDBService.tableReports, newReport).then(function () {
                    _this.snackbarService.success('Report saved!');
                    newReport['indexedDBMethod'] = '2-update';
                    _this.indexedDBService.database.add(_this.indexedDBService.tableReportsPush, newReport).then(function () {
                    }, function (error) { return new Error(error); });
                    _this.router.navigate(['/reports/' + report_id + '/view']).then(function () {
                    });
                }, function (error) {
                    _this.snackbarService.error('An error occurred while loading the data, please try again.');
                    throw new Error(error);
                });
            }, function (error) { return new Error(error); });
        }
    };
    ReportsService.prototype.deleteReport = function (report_id) {
        var _this = this;
        return new Promise(function (resolve) {
            var dialogRef;
            dialogRef = _this.dialogService.open(SimpleModalDialogComponent);
            dialogRef.componentInstance.action_type = 'delete';
            dialogRef.componentInstance.text = 'Weet u zeker dat u deze rapportage wilt verwijderen?';
            dialogRef.beforeClosed().subscribe(function (response) {
                if (response === true) {
                    if (_this.apiService.isOnline) {
                        return _this.apiService.delete('reports/' + report_id).then(function (data) {
                            if (data.success === true) {
                                _this.indexedDBService.database.delete(_this.indexedDBService.tableReports, report_id).then(function () {
                                }, function (error) { return new Error(error); });
                                _this.snackbarService.success('Report deleted.');
                                _this.cleanupAfterReportDeletion([report_id]);
                                resolve(true);
                            }
                            else {
                                _this.snackbarService.error('An error occurred while loading the data, please try again.');
                                resolve(false);
                            }
                        });
                    }
                    else {
                        _this.indexedDBService.database.delete(_this.indexedDBService.tableReports, report_id).then(function () {
                            _this.snackbarService.success('Report deleted.');
                            _this.indexedDBService.database.add(_this.indexedDBService.tableReportsPush, {
                                id: report_id,
                                indexedDBMethod: '3-delete'
                            }).then(function () {
                            }, function (error) { return new Error(error); });
                            _this.cleanupAfterReportDeletion([report_id]);
                            resolve(true);
                        }, function (error) {
                            _this.snackbarService.error('An error occurred while loading the data, please try again.');
                            throw new Error(error);
                        });
                    }
                }
            });
        });
    };
    ReportsService.prototype.sendToEmail = function (report_id, formData) {
        var _this = this;
        if (formData['custom_contacts'] !== '') {
            if (this.apiService.isOnline) {
                this.apiService.post('reports/' + report_id + '/send-to-email', formData).then(function (data) {
                    if (data.success === true) {
                        _this.snackbarService.success('Report sent!');
                        _this.router.navigate(['/reports/' + report_id + '/view']).then(function () {
                        });
                    }
                    else if (typeof data.error !== 'undefined') {
                        _this.errors.next(data.error);
                    }
                    else {
                        _this.snackbarService.error('An error occurred while loading the data, please try again.');
                    }
                });
            }
            else {
                formData['report_id'] = report_id;
                this.indexedDBService.database.add(this.indexedDBService.tableReportsSendToClient, formData).then(function () {
                    _this.snackbarService.success('Report will be sent when there is an internet connection again.');
                    _this.router.navigate(['/reports/' + report_id + '/view']).then(function () {
                    });
                }, function (error) {
                    _this.snackbarService.error('An error occurred while loading the data, please try again.');
                    throw new Error(error);
                });
            }
        }
        else {
            this.snackbarService.warning('Select at least one contact person or enter an email address manually.');
        }
    };
    ReportsService.prototype.copyReport = function (report_id, type) {
        var _this = this;
        if (type === void 0) { type = 'red'; }
        if (this.apiService.isOnline) {
            this.apiService.get('reports/' + report_id + '/copy/' + type).then(function (data) {
                if (typeof data !== 'undefined') {
                    if (typeof data.data !== 'undefined') {
                        if (typeof data.data.link !== 'undefined') {
                            var promises = [
                                _this.indexedDBService.syncReports(),
                                _this.indexedDBService.syncReportsAttachments(),
                                _this.indexedDBService.syncReportsComments(),
                                _this.indexedDBService.syncReportsBuildings(),
                                _this.indexedDBService.syncReportsBuildingsReports(),
                                _this.indexedDBService.syncTypes(),
                                _this.indexedDBService.syncActions()
                            ];
                            Promise.all(promises).then(function () {
                                _this.previousUrlService.goTo(data.data.link);
                                _this.snackbarService.success('Report copied successfully!');
                            });
                        }
                    }
                }
            });
        }
        else {
            this.snackbarService.warning('This function is only available when there is an internet connection.');
            /*
                        this.indexedDBService.database.getByKey(this.indexedDBService.tableReports, report_id).then(report => {
                            if (typeof report !== 'undefined') {
                                let new_report_id = moment().valueOf(),
                                    promises = [];
            
                                report['parent_report_id'] = report['id'];
                                report['id'] = new_report_id;
                                report['created'] = moment().valueOf();
                                report['status'] = 'open';
                                report['status_text'] = 'Open';
                                report['report_nr'] = report['report_nr'] + '-Offline';
                                report['is_offline'] = 'yes';
                                delete report['updated'];
            
                                // save report
                                promises.push(new Promise(resolve0 => {
                                    // console.log(JSON.parse(JSON.stringify(report)));
                                    this.indexedDBService.database.add(this.indexedDBService.tableReports, report).then(() => {
                                        let promises1 = [];
            
                                        report['indexedDBMethod'] = '1-create';
            
                                        promises1.push(new Promise(resolve1 => {
                                            // console.log(JSON.parse(JSON.stringify(report)));
                                            this.indexedDBService.database.add(this.indexedDBService.tableReportsPush, report).then(() => {
                                                let promises2 = [];
            
                                                // copy report's attachments
                                                promises2.push(new Promise(resolve2 => {
                                                    this.indexedDBService.database.getAllFast(this.indexedDBService.tableReportsAttachments).then(attachmentsDB => {
                                                        let promises3 = [];
                                                        if (typeof attachmentsDB !== 'undefined') {
                                                            let attachments = attachmentsDB.filter(data => data.report_id === report_id);
                                                            attachments.forEach(attachment => {
                                                                let id = JSON.parse(JSON.stringify(attachment.id));
                                                                attachment['id'] = moment().valueOf() + Math.floor((Math.random() * (500000 - 1) + 1));
                                                                attachment['created'] = report['created'];
                                                                attachment['report_id'] = report['id'];
                                                                delete attachment['download_url'];
            
                                                                promises3.push(new Promise(resolve3 => {
                                                                    // console.log(JSON.parse(JSON.stringify(attachment)));
                                                                    this.indexedDBService.database.add(this.indexedDBService.tableReportsAttachments, attachment).then(() => {
                                                                        attachment['copy_from_attachment_id'] = id;
                                                                        attachment['copy_from_report_id'] = report_id;
                                                                        attachment['indexedDBMethod'] = '0-copy';
            
                                                                        // console.log(JSON.parse(JSON.stringify(attachment)));
                                                                        this.indexedDBService.database.add(this.indexedDBService.tableReportsAttachmentsPush, attachment).then(() => {
                                                                            resolve3();
                                                                        });
                                                                    });
                                                                }));
                                                            });
                                                        }
            
                                                        Promise.all(promises3).then(() => {
                                                            resolve2();
                                                        });
                                                    });
                                                }));
            
                                                // copy report's buildings
                                                promises2.push(new Promise(resolve2 => {
                                                    this.indexedDBService.database.getAllFast(this.indexedDBService.tableReportsBuildings).then(buildingsDB => {
                                                        if (typeof buildingsDB !== 'undefined') {
                                                            let buildings = buildingsDB.filter(data => data.report_id === report_id),
                                                                promises3 = [];
            
                                                            buildings.forEach(building => {
                                                                let building_id = parseInt(JSON.parse(JSON.stringify(building['id'])), 10);
                                                                building['report_id'] = report['id'];
                                                                building['id'] = moment().valueOf() + Math.floor((Math.random() * (500000 - 1) + 1));
            
                                                                if (typeof building.lib_particular_id !== 'undefined') {
                                                                    if (building.lib_particular_id.length === 0) {
                                                                        building['lib_particular_id'] = [];
                                                                    }
                                                                }
            
                                                                promises3.push(new Promise(resolve3 => {
                                                                    // console.log(JSON.parse(JSON.stringify(building)));
                                                                    this.indexedDBService.database.add(this.indexedDBService.tableReportsBuildings, building).then(() => {
                                                                        let promises4 = [];
            
                                                                        building['indexedDBMethod'] = '1-create';
                                                                        promises4.push(new Promise(resolve4 => {
                                                                            // console.log(JSON.parse(JSON.stringify(building)));
                                                                            this.indexedDBService.database.add(this.indexedDBService.tableReportsBuildingsPush, building).then(() => {
                                                                                resolve4();
                                                                            });
                                                                        }));
            
                                                                        // copy building reports
                                                                        promises4.push(new Promise(resolve4 => {
                                                                            this.indexedDBService.database.getAllFast(this.indexedDBService.tableReportsBuildingsReports).then(buildingReportsDB => {
                                                                                let promises5 = [];
            
                                                                                if (typeof buildingReportsDB !== 'undefined') {
                                                                                    let buildingReports = buildingReportsDB.filter(data => data.report_id === report_id && data.building_id === building_id);
                                                                                    buildingReports.forEach(buildingReport => {
                                                                                        buildingReport['report_id'] = report['id'];
                                                                                        buildingReport['building_id'] = building['id'];
                                                                                        buildingReport['id'] = moment().valueOf() + Math.floor((Math.random() * (500000 - 1) + 1));
                                                                                        buildingReport['indexedDBMethod'] = '1-create';
                                                                                        delete buildingReport['photos'];
            
                                                                                        promises5.push(new Promise(resolve5 => {
                                                                                            // console.log(JSON.parse(JSON.stringify(buildingReport)));
                                                                                            this.indexedDBService.database.add(this.indexedDBService.tableReportsBuildingsReports, buildingReport).then(() => {
                                                                                                let promises6 = [];
            
                                                                                                promises6.push(new Promise(resolve6 => {
                                                                                                    // console.log(JSON.parse(JSON.stringify(buildingReport)));
                                                                                                    this.indexedDBService.database.add(this.indexedDBService.tableReportsBuildingsReportsPush, buildingReport).then(() => {
                                                                                                        resolve6();
                                                                                                    });
                                                                                                }));
            
                                                                                                Promise.all(promises6).then(() => {
                                                                                                    resolve5();
                                                                                                });
                                                                                            }).catch(error => console.log(error));
                                                                                        }));
                                                                                    });
                                                                                }
            
                                                                                Promise.all(promises5).then(() => {
                                                                                    resolve4();
                                                                                });
                                                                            });
                                                                        }));
            
                                                                        Promise.all(promises4).then(() => {
                                                                            resolve3();
                                                                        });
                                                                    });
                                                                }));
                                                            });
            
                                                            Promise.all(promises3).then(() => {
                                                                resolve2();
                                                            });
                                                        } else {
                                                            resolve2();
                                                        }
                                                    });
                                                }));
            
                                                Promise.all(promises2).then(() => {
                                                    resolve1();
                                                });
                                            });
                                        }));
            
                                        Promise.all(promises1).then(() => {
                                            resolve0();
                                        });
                                    });
                                }));
            
                                Promise.all(promises).then(() => {
                                    // console.log('copy done, redirect ...', new_report_id);
                                    this.router.navigate(['/reports/' + new_report_id + '/view']).then(() => {
                                        this.snackbarService.success('Rapport kopiëren geslaagd!');
                                        this.indexedDBService.database.delete('reports_reinspections', report_id);
                                    });
                                }).catch(() => {
                                    console.log('error, cleaning up ...');
                                    this.indexedDBService.database.delete(this.indexedDBService.tableReports, report['id']);
                                    this.indexedDBService.database.delete(this.indexedDBService.tableReportsPush, report['id']);
                                    this.deleteReportAttachments(report['id']).then(() => {
                                    });
                                    this.deleteReportBuildings(report['id']).then(() => {
                                    });
                                    this.deleteReportBuildingsReports(report['id']).then(() => {
                                    });
                                });
                            }
                        });
            */
        }
    };
    ReportsService.prototype.getAttachments = function (report_id, formData) {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                resolve(_this.getAttachmentsWhenOnline(report_id, formData));
            }
            else {
                resolve(_this.getAttachmentsWhenOffline(report_id, formData));
            }
        });
    };
    ReportsService.prototype.getAttachmentsWhenOffline = function (report_id, formData) {
        var _this = this;
        return new Promise(function (resolve) {
            _this.indexedDBService.databaseReady.subscribe(function (event) {
                if (event) {
                    var resultsData_1 = [], collectionData_1 = [], startIndex_2 = 0, endIndex_2 = -1, searchString_2 = null;
                    if (formData !== null) {
                        if (typeof formData['start'] !== 'undefined' && typeof formData['length'] !== 'undefined') {
                            startIndex_2 = formData['start'];
                            endIndex_2 = formData['length'];
                            if (endIndex_2 !== -1) {
                                endIndex_2 += startIndex_2;
                            }
                        }
                        if (typeof formData['columns[0][search][value]'] !== 'undefined') {
                            if (formData['columns[0][search][value]'] !== null && formData['columns[0][search][value]'] !== '') {
                                searchString_2 = formData['columns[0][search][value]'].toLowerCase();
                            }
                        }
                    }
                    _this.indexedDBService.database.getAllFast(_this.indexedDBService.tableReportsAttachments).then(function (attachmentsDB) {
                        if (typeof attachmentsDB !== 'undefined') {
                            collectionData_1 = attachmentsDB.filter(function (attachments) { return attachments.report_id === report_id && (typeof attachments.indexedDBMethod === 'undefined' || typeof attachments.indexedDBMethod !== 'undefined' && attachments.indexedDBMethod !== '3-delete'); });
                            collectionData_1.sort(function (a, b) { return a.created > b.created ? -1 : 1; });
                            if (searchString_2 !== null) {
                                collectionData_1 = collectionData_1.filter(function (attachments) { return attachments.title.toLowerCase().indexOf(searchString_2) !== -1; });
                            }
                            if (endIndex_2 !== -1) {
                                resultsData_1 = collectionData_1.slice(startIndex_2, endIndex_2);
                            }
                            else {
                                resultsData_1 = collectionData_1.slice();
                            }
                            resolve({
                                recordsTotal: collectionData_1.length,
                                data: resultsData_1
                            });
                        }
                        else {
                            resolve(undefined);
                        }
                    }, function (error) {
                        resolve(undefined);
                        throw new Error(error);
                    });
                }
            });
        });
    };
    ReportsService.prototype.getAttachmentsWhenOnline = function (report_id, formData) {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                _this.apiService.get('reports/' + report_id + '/attachments', formData).then(function (data) {
                    if (typeof data !== 'undefined') {
                        if (typeof data.success !== 'undefined') {
                            if (data.success === true) {
                                if (typeof data.data !== 'undefined') {
                                    resolve(data);
                                }
                                else {
                                    resolve(undefined);
                                }
                            }
                            else {
                                resolve(undefined);
                            }
                        }
                        else {
                            resolve(undefined);
                        }
                    }
                    else {
                        resolve(undefined);
                    }
                }).catch(function (error) {
                    resolve(undefined);
                    throw new Error(error);
                });
            }
            else {
                resolve(undefined);
            }
        });
    };
    ReportsService.prototype.createAttachment = function (report_id, formData) {
        var _this = this;
        if (this.apiService.isOnline) {
            this.apiService.post('reports/' + report_id + '/attachments', formData).then(function (data) {
                if (data.success === true) {
                    _this.snackbarService.success('Attachment saved.');
                    if (typeof data.data !== 'undefined') {
                        _this.indexedDBService.database.add(_this.indexedDBService.tableReportsAttachments, data.data).then(function () {
                        }, function (error) { return new Error(error); });
                    }
                    _this.router.navigate(['/reports/' + report_id + '/view']).then(function () {
                    });
                }
                else if (typeof data.error !== 'undefined') {
                    _this.errors.next(data.error);
                }
                else {
                    _this.snackbarService.error('An error occurred while loading the data, please try again.');
                }
            });
        }
        else {
            var _authenticationService = this.authenticationService.user.first().subscribe(function (user) {
                formData['id'] = moment().valueOf();
                formData['indexedDBMethod'] = '1-create';
                formData['report_id'] = report_id;
                formData['created'] = moment().valueOf();
                var newFormData = {
                    id: formData.id,
                    report_id: report_id,
                    filename: formData.filename._files[0].name,
                    original_filename: formData.filename._files[0].name,
                    title: formData['title'],
                    description: formData['description'],
                    inspector: user.display_name,
                    created: formData['created']
                };
                _this.indexedDBService.database.add(_this.indexedDBService.tableReportsAttachments, newFormData).then(function () {
                    _this.snackbarService.success('Attachment saved.');
                    _this.indexedDBService.database.add(_this.indexedDBService.tableReportsAttachmentsPush, formData);
                    _this.router.navigate(['/reports/' + report_id + '/view']).then(function () {
                    });
                }, function (error) {
                    _this.snackbarService.error('An error occurred while loading the data, please try again.');
                    throw new Error(error);
                });
            });
            _authenticationService.unsubscribe();
        }
    };
    ReportsService.prototype.deleteAttachment = function (report_id, attachment_id) {
        var _this = this;
        return new Promise(function (resolve) {
            var dialogRef;
            dialogRef = _this.dialogService.open(SimpleModalDialogComponent);
            dialogRef.componentInstance.action_type = 'delete';
            dialogRef.componentInstance.text = 'Are you sure you want to delete this attachment?';
            dialogRef.beforeClosed().subscribe(function (response) {
                if (response === true) {
                    if (_this.apiService.isOnline) {
                        return _this.apiService.delete('reports/' + report_id + '/attachments/' + attachment_id).then(function (data) {
                            if (data.success === true) {
                                _this.snackbarService.success('Attachment deleted.');
                                _this.indexedDBService.database.delete(_this.indexedDBService.tableReportsAttachments, attachment_id).then(function () {
                                }, function (error) { return new Error(error); });
                                resolve(true);
                            }
                            else {
                                _this.snackbarService.error('An error occurred while loading the data, please try again.');
                                resolve(false);
                            }
                        });
                    }
                    else {
                        _this.indexedDBService.database.delete(_this.indexedDBService.tableReportsAttachments, attachment_id).then(function () {
                            _this.snackbarService.success('Attachment deleted.');
                            var formData = {
                                'report_id': report_id,
                                'id': attachment_id,
                                'indexedDBMethod': '3-delete'
                            };
                            _this.indexedDBService.database.add(_this.indexedDBService.tableReportsAttachmentsPush, formData).then(function () {
                            }, function (error) { return new Error(error); });
                        }, function (error) {
                            _this.snackbarService.error('An error occurred while loading the data, please try again.');
                            throw new Error(error);
                        });
                    }
                }
            });
        });
    };
    ReportsService.prototype.getComments = function (report_id, formData) {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                resolve(_this.getCommentsWhenOnline(report_id, formData));
            }
            else {
                resolve(_this.getCommentsWhenOffline(report_id, formData));
            }
        });
    };
    ReportsService.prototype.getCommentsWhenOffline = function (report_id, formData) {
        var _this = this;
        return new Promise(function (resolve) {
            _this.indexedDBService.databaseReady.subscribe(function (event) {
                if (event) {
                    var resultsData_2 = [], collectionData_2 = [], startIndex_3 = 0, endIndex_3 = -1, searchString_3 = null;
                    if (formData !== null) {
                        if (typeof formData['start'] !== 'undefined' && typeof formData['length'] !== 'undefined') {
                            startIndex_3 = formData['start'];
                            endIndex_3 = formData['length'];
                            if (endIndex_3 !== -1) {
                                endIndex_3 += startIndex_3;
                            }
                        }
                        if (typeof formData['columns[0][search][value]'] !== 'undefined') {
                            if (formData['columns[0][search][value]'] !== null && formData['columns[0][search][value]'] !== '') {
                                searchString_3 = formData['columns[0][search][value]'].toLowerCase();
                            }
                        }
                    }
                    _this.indexedDBService.database.getAllFast(_this.indexedDBService.tableReportsComments).then(function (commentsDB) {
                        if (typeof commentsDB !== 'undefined') {
                            collectionData_2 = commentsDB.filter(function (comments) { return comments.report_id === report_id && (typeof comments.indexedDBMethod === 'undefined' || typeof comments.indexedDBMethod !== 'undefined' && comments.indexedDBMethod !== '3-delete'); });
                            collectionData_2.sort(function (a, b) { return a.created > b.created ? 1 : -1; });
                            if (searchString_3 !== null) {
                                collectionData_2 = collectionData_2.filter(function (comments) { return comments.inspector.toLowerCase().indexOf(searchString_3) !== -1; });
                            }
                            if (endIndex_3 !== -1) {
                                resultsData_2 = collectionData_2.slice(startIndex_3, endIndex_3);
                            }
                            else {
                                resultsData_2 = collectionData_2.slice();
                            }
                            resolve({
                                recordsTotal: collectionData_2.length,
                                data: resultsData_2
                            });
                        }
                        else {
                            resolve(undefined);
                        }
                    }, function (error) {
                        resolve(undefined);
                        throw new Error(error);
                    });
                }
            });
        });
    };
    ReportsService.prototype.getCommentsWhenOnline = function (report_id, formData) {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                _this.apiService.get('reports/' + report_id + '/comments', formData).then(function (data) {
                    if (typeof data !== 'undefined') {
                        if (typeof data.success !== 'undefined') {
                            if (data.success === true) {
                                if (typeof data.data !== 'undefined') {
                                    resolve(data);
                                }
                                else {
                                    resolve(undefined);
                                }
                            }
                            else {
                                resolve(undefined);
                            }
                        }
                        else {
                            resolve(undefined);
                        }
                    }
                    else {
                        resolve(undefined);
                    }
                }).catch(function (error) {
                    resolve(undefined);
                    throw new Error(error);
                });
            }
            else {
                resolve(undefined);
            }
        });
    };
    ReportsService.prototype.getComment = function (report_id, comment_id) {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                resolve(_this.getCommentWhenOnline(report_id, comment_id));
            }
            else {
                resolve(_this.getCommentWhenOffline(report_id, comment_id));
            }
        });
    };
    ReportsService.prototype.getCommentWhenOffline = function (report_id, comment_id) {
        var _this = this;
        return new Promise(function (resolve) {
            _this.indexedDBService.databaseReady.subscribe(function (event) {
                if (event) {
                    _this.indexedDBService.database.getByKey(_this.indexedDBService.tableReportsComments, comment_id).then(function (commentDB) {
                        if (typeof commentDB !== 'undefined') {
                            resolve(commentDB);
                        }
                        else {
                            resolve(undefined);
                        }
                    }, function () {
                        resolve(undefined);
                    });
                }
            });
        });
    };
    ReportsService.prototype.getCommentWhenOnline = function (report_id, comment_id) {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                _this.apiService.get('reports/' + report_id + '/comments/' + comment_id).then(function (data) {
                    if (typeof data !== 'undefined') {
                        if (typeof data.success !== 'undefined') {
                            if (data.success === true) {
                                if (typeof data.data !== 'undefined') {
                                    if (typeof data.data[0] !== 'undefined') {
                                        resolve(data.data[0]);
                                    }
                                    else {
                                        resolve(undefined);
                                    }
                                }
                                else {
                                    resolve(undefined);
                                }
                            }
                            else {
                                resolve(undefined);
                            }
                        }
                        else {
                            resolve(undefined);
                        }
                    }
                    else {
                        resolve(undefined);
                    }
                }).catch(function (error) {
                    resolve(undefined);
                    throw new Error(error);
                });
            }
            else {
                resolve(undefined);
            }
        });
    };
    ReportsService.prototype.createComment = function (report_id, formData) {
        var _this = this;
        if (this.apiService.isOnline) {
            this.apiService.post('reports/' + report_id + '/comments', formData).then(function (data) {
                if (data.success === true) {
                    _this.snackbarService.success('Comment saved.');
                    if (typeof data.data !== 'undefined') {
                        _this.indexedDBService.database.add(_this.indexedDBService.tableReportsComments, data.data).then(function () {
                        }, function (error) { return new Error(error); });
                    }
                    _this.router.navigate(['/reports/' + report_id + '/view']).then(function () {
                    });
                }
                else if (typeof data.error !== 'undefined') {
                    _this.errors.next(data.error);
                }
                else {
                    _this.snackbarService.error('An error occurred while loading the data, please try again.');
                }
            });
        }
        else {
            var _authenticationService = this.authenticationService.user.first().subscribe(function (user) {
                formData['id'] = moment().valueOf();
                formData['report_id'] = report_id;
                formData['account_user_id'] = user.id;
                formData['inspector'] = user.display_name;
                formData['created'] = moment().valueOf();
                _this.indexedDBService.database.add(_this.indexedDBService.tableReportsComments, formData).then(function () {
                    _this.snackbarService.success('Comment saved.');
                    formData['indexedDBMethod'] = '1-create';
                    _this.indexedDBService.database.add(_this.indexedDBService.tableReportsCommentsPush, formData).then(function () {
                    }, function (error) { return new Error(error); });
                    _this.router.navigate(['/reports/' + report_id + '/view']).then(function () {
                    });
                }, function (error) { return new Error(error); });
            });
            _authenticationService.unsubscribe();
        }
    };
    ReportsService.prototype.updateComment = function (report_id, comment_id, formData) {
        var _this = this;
        if (this.apiService.isOnline) {
            this.apiService.post('reports/' + report_id + '/comments/' + comment_id, formData).then(function (data) {
                if (data.success === true) {
                    _this.snackbarService.success('Comment saved.');
                    if (typeof data.data !== 'undefined') {
                        _this.indexedDBService.database.update(_this.indexedDBService.tableReportsComments, data.data).then(function () {
                        }, function (error) { return new Error(error); });
                    }
                    _this.router.navigate(['/reports/' + report_id + '/view']).then(function () {
                    });
                }
                else if (typeof data.error !== 'undefined') {
                    _this.errors.next(data.error);
                }
                else {
                    _this.snackbarService.error('An error occurred while loading the data, please try again.');
                }
            });
        }
        else {
            this.indexedDBService.database.getByIndex(this.indexedDBService.tableReportsComments, 'id', comment_id).then(function (comment) {
                comment['comment'] = formData['comment'];
                _this.indexedDBService.database.update(_this.indexedDBService.tableReportsComments, comment).then(function () {
                    _this.snackbarService.success('Comment saved.');
                    comment['indexedDBMethod'] = '2-update';
                    _this.indexedDBService.database.add(_this.indexedDBService.tableReportsCommentsPush, comment).then(function () {
                    }, function (error) { return new Error(error); });
                    _this.router.navigate(['/reports/' + report_id + '/view']).then(function () {
                    });
                }, function (error) { return new Error(error); });
            }, function (error) { return new Error(error); });
        }
    };
    ReportsService.prototype.getBuildings = function (report_id, formData) {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                resolve(_this.getBuildingsWhenOnline(report_id, formData));
            }
            else {
                resolve(_this.getBuildingsWhenOffline(report_id, formData));
            }
        });
    };
    ReportsService.prototype.getBuildingsWhenOffline = function (report_id, formData) {
        var _this = this;
        return new Promise(function (resolve) {
            _this.indexedDBService.databaseReady.subscribe(function (event) {
                if (event) {
                    var resultsData_3 = [], collectionData_3 = [], startIndex_4 = 0, endIndex_4 = -1, searchString_4 = null;
                    if (formData !== null) {
                        if (typeof formData['start'] !== 'undefined' && typeof formData['length'] !== 'undefined') {
                            startIndex_4 = formData['start'];
                            endIndex_4 = formData['length'];
                            if (endIndex_4 !== -1) {
                                endIndex_4 += startIndex_4;
                            }
                        }
                        if (typeof formData['columns[3][search][value]'] !== 'undefined') {
                            if (formData['columns[3][search][value]'] !== null && formData['columns[3][search][value]'] !== '') {
                                searchString_4 = formData['columns[3][search][value]'].toLowerCase();
                            }
                        }
                    }
                    _this.indexedDBService.database.getAllFast(_this.indexedDBService.tableReportsBuildings).then(function (reportBuildingsDB) {
                        if (typeof reportBuildingsDB !== 'undefined') {
                            collectionData_3 = reportBuildingsDB.filter(function (collection2) { return collection2.report_id === report_id; });
                            collectionData_3.sort(function (a, b) { return a.sequence > b.sequence ? -1 : 1; });
                            if (searchString_4 !== null) {
                                collectionData_3 = collectionData_3.filter(function (collection2) { return collection2.name.toLowerCase().indexOf(searchString_4) !== -1; });
                            }
                            if (endIndex_4 !== -1) {
                                resultsData_3 = collectionData_3.slice(startIndex_4, endIndex_4);
                            }
                            else {
                                resultsData_3 = collectionData_3.slice();
                            }
                            resolve({
                                recordsTotal: collectionData_3.length,
                                data: resultsData_3
                            });
                        }
                        else {
                            resolve(undefined);
                        }
                    }, function (error) {
                        resolve(undefined);
                        throw new Error(error);
                    });
                }
            });
        });
    };
    ReportsService.prototype.getBuildingsWhenOnline = function (report_id, formData) {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                _this.apiService.get('reports/' + report_id + '/buildings', formData).then(function (data) {
                    if (typeof data !== 'undefined') {
                        if (typeof data.success !== 'undefined') {
                            if (data.success === true) {
                                if (typeof data.data !== 'undefined') {
                                    resolve(data);
                                }
                                else {
                                    resolve(undefined);
                                }
                            }
                            else {
                                resolve(undefined);
                            }
                        }
                        else {
                            resolve(undefined);
                        }
                    }
                    else {
                        resolve(undefined);
                    }
                }).catch(function (error) {
                    resolve(undefined);
                    throw new Error(error);
                });
            }
            else {
                resolve(undefined);
            }
        });
    };
    ReportsService.prototype.getBuilding = function (report_id, formData) {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                resolve(_this.getBuildingWhenOnline(report_id, formData));
            }
            else {
                resolve(_this.getBuildingWhenOffline(report_id, formData));
            }
        });
    };
    ReportsService.prototype.getBuildingWhenOffline = function (report_id, building_id) {
        var _this = this;
        return new Promise(function (resolve) {
            _this.indexedDBService.databaseReady.subscribe(function (event) {
                if (event) {
                    _this.indexedDBService.database.getByKey(_this.indexedDBService.tableReportsBuildings, building_id).then(function (reportBuildingDB) {
                        if (typeof reportBuildingDB !== 'undefined') {
                            resolve(reportBuildingDB);
                        }
                        else {
                            resolve(undefined);
                        }
                    }, function (error) {
                        resolve(undefined);
                        throw new Error(error);
                    });
                }
            });
        });
    };
    ReportsService.prototype.getBuildingWhenOnline = function (report_id, building_id) {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                _this.apiService.get('reports/' + report_id + '/buildings/' + building_id).then(function (data) {
                    if (typeof data !== 'undefined') {
                        if (typeof data.success !== 'undefined') {
                            if (data.success === true) {
                                if (typeof data.data !== 'undefined') {
                                    if (typeof data.data[0] !== 'undefined') {
                                        resolve(data.data[0]);
                                    }
                                    else {
                                        resolve(undefined);
                                    }
                                }
                                else {
                                    resolve(undefined);
                                }
                            }
                            else {
                                resolve(undefined);
                            }
                        }
                        else {
                            resolve(undefined);
                        }
                    }
                    else {
                        resolve(undefined);
                    }
                }).catch(function (error) {
                    resolve(undefined);
                    throw new Error(error);
                });
            }
            else {
                resolve(undefined);
            }
        });
    };
    ReportsService.prototype.createBuilding = function (report_id, formData) {
        var _this = this;
        if (this.apiService.isOnline) {
            this.apiService.post('reports/' + report_id + '/buildings', formData).then(function (data) {
                if (data.success === true) {
                    _this.snackbarService.success('Building saved.');
                    if (typeof data.data !== 'undefined') {
                        _this.indexedDBService.database.add(_this.indexedDBService.tableReportsBuildings, data.data).then(function () {
                        }, function (error) { return new Error(error); });
                        _this.router.navigate(['/reports/' + report_id + '/buildings/' + data.data.id + '/reports']).then(function () {
                        });
                    }
                    else {
                        _this.router.navigate(['/reports/' + report_id + '/buildings']).then(function () {
                        });
                    }
                }
                else if (typeof data.error !== 'undefined') {
                    _this.errors.next(data.error);
                }
                else {
                    _this.snackbarService.error('An error occurred while loading the data, please try again.');
                }
            });
        }
        else {
            formData['id'] = moment().valueOf();
            formData['report_id'] = report_id;
            formData['created'] = moment().valueOf();
            formData['sequence'] = 1;
            delete formData['query'];
            this.indexedDBService.database.getAllFast(this.indexedDBService.tableReportsBuildings).then(function (buildings) {
                if (typeof buildings !== 'undefined') {
                    buildings.forEach(function (building) {
                        if (building.report_id === report_id) {
                            if (typeof building.sequence !== 'undefined') {
                                if (formData['sequence'] <= building.sequence) {
                                    formData['sequence'] = parseInt(building.sequence, 10) + 1;
                                }
                            }
                        }
                    });
                }
                _this.indexedDBService.database.add(_this.indexedDBService.tableReportsBuildings, formData).then(function () {
                    _this.snackbarService.success('Building saved.');
                    formData['indexedDBMethod'] = '1-create';
                    _this.indexedDBService.database.add(_this.indexedDBService.tableReportsBuildingsPush, formData).then(function () {
                    }, function (error) { return new Error(error); });
                    _this.router.navigate(['/reports/' + report_id + '/buildings/' + formData.id + '/reports']).then();
                }, function (error) { return new Error(error); });
            }, function (error) { return new Error(error); });
        }
    };
    ReportsService.prototype.updateBuilding = function (report_id, building_id, formData) {
        var _this = this;
        if (this.apiService.isOnline) {
            this.apiService.post('reports/' + report_id + '/buildings/' + building_id, formData).then(function (data) {
                if (data.success === true) {
                    _this.snackbarService.success('Building saved.');
                    if (typeof data.data !== 'undefined') {
                        _this.indexedDBService.database.update(_this.indexedDBService.tableReportsBuildings, data.data).then(function () {
                        }, function (error) { return new Error(error); });
                        _this.router.navigate(['/reports/' + report_id + '/buildings/' + building_id + '/reports']).then(function () {
                        });
                    }
                    else {
                        _this.router.navigate(['/reports/' + report_id + '/buildings']).then(function () {
                        });
                    }
                }
                else if (typeof data.error !== 'undefined') {
                    _this.errors.next(data.error);
                }
                else {
                    _this.snackbarService.error('An error occurred while loading the data, please try again.');
                }
            });
        }
        else {
            this.indexedDBService.database.getByIndex(this.indexedDBService.tableReportsBuildings, 'id', building_id).then(function (building) {
                delete formData['query'];
                var newBuilding = __assign({}, building, formData);
                newBuilding['updated'] = moment().valueOf();
                _this.indexedDBService.database.update(_this.indexedDBService.tableReportsBuildings, newBuilding).then(function () {
                    _this.snackbarService.success('Building saved.');
                    newBuilding['indexedDBMethod'] = '2-update';
                    _this.indexedDBService.database.add(_this.indexedDBService.tableReportsBuildingsPush, newBuilding).then(function () {
                    }, function (error) { return new Error(error); });
                    _this.router.navigate(['/reports/' + report_id + '/buildings/' + building_id + '/reports']).then();
                }, function (error) { return new Error(error); });
            }, function (error) { return new Error(error); });
        }
    };
    ReportsService.prototype.deleteBuilding = function (report_id, building_id) {
        var _this = this;
        return new Promise(function (resolve) {
            var dialogRef;
            dialogRef = _this.dialogService.open(SimpleModalDialogComponent);
            dialogRef.componentInstance.action_type = 'delete';
            dialogRef.componentInstance.text = 'Are you sure you want to delete this building?';
            dialogRef.beforeClosed().subscribe(function (response) {
                if (response === true) {
                    if (_this.apiService.isOnline) {
                        return _this.apiService.delete('reports/' + report_id + '/buildings/' + building_id).then(function (data) {
                            if (data.success === true) {
                                _this.snackbarService.success('Building deleted.');
                                _this.indexedDBService.database.delete(_this.indexedDBService.tableReportsBuildings, building_id).then(function () {
                                    resolve(true);
                                }, function (error) {
                                    resolve(true);
                                    throw new Error(error);
                                });
                            }
                            else {
                                _this.snackbarService.error('Error occurred while deleting building.');
                                resolve(false);
                            }
                        }, function (error) {
                            resolve(undefined);
                            throw new Error(error);
                        });
                    }
                    else {
                        _this.indexedDBService.database.delete(_this.indexedDBService.tableReportsBuildings, building_id).then(function () {
                            _this.snackbarService.success('Building deleted.');
                            _this.indexedDBService.database.add(_this.indexedDBService.tableReportsBuildingsPush, {
                                id: building_id,
                                report_id: report_id,
                                indexedDBMethod: '3-delete'
                            }).then(function () {
                                _this.deleteReportBuildingsReports(report_id, building_id).then(function () {
                                    resolve(true);
                                });
                            }, function (error) {
                                resolve(undefined);
                                throw new Error(error);
                            });
                        }, function (error) {
                            resolve(undefined);
                            throw new Error(error);
                        });
                    }
                }
            });
        });
    };
    ReportsService.prototype.getBuildingReports = function (report_id, building_id, formData) {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                resolve(_this.getBuildingReportsWhenOnline(report_id, building_id, formData));
            }
            else {
                resolve(_this.getBuildingReportsWhenOffline(report_id, building_id, formData));
            }
        });
    };
    ReportsService.prototype.getBuildingReportsWhenOffline = function (report_id, building_id, formData) {
        var _this = this;
        return new Promise(function (resolve) {
            _this.indexedDBService.databaseReady.subscribe(function (event) {
                if (event) {
                    var resultsData_4 = [], collectionData_4 = [], startIndex_5 = 0, endIndex_5 = -1, searchString_5 = null;
                    if (formData !== null) {
                        if (typeof formData['start'] !== 'undefined' && typeof formData['length'] !== 'undefined') {
                            startIndex_5 = formData['start'];
                            endIndex_5 = formData['length'];
                            endIndex_5 += startIndex_5;
                        }
                        if (typeof formData['columns[0][search][value]'] !== 'undefined') {
                            if (formData['columns[0][search][value]'] !== null && formData['columns[0][search][value]'] !== '') {
                                searchString_5 = formData['columns[0][search][value]'].toLowerCase();
                            }
                        }
                    }
                    _this.indexedDBService.database.getAllFast(_this.indexedDBService.tableReportsBuildingsReports).then(function (buildingReportsDB) {
                        if (typeof buildingReportsDB !== 'undefined') {
                            collectionData_4 = buildingReportsDB.filter(function (collection2) { return collection2.report_id === report_id && collection2.building_id === building_id && (typeof collection2.indexedDBMethod === 'undefined' || typeof collection2.indexedDBMethod !== 'undefined' && collection2.indexedDBMethod !== '3-delete'); });
                            collectionData_4.sort(function (a, b) { return a.location_nr > b.location_nr ? 1 : -1; });
                            if (searchString_5 !== null) {
                                collectionData_4 = collectionData_4.filter(function (collection2) { return collection2.location_nr.toLowerCase().indexOf(searchString_5) !== -1; });
                            }
                            if (endIndex_5 !== -1) {
                                resultsData_4 = collectionData_4.slice(startIndex_5, endIndex_5);
                            }
                            else {
                                resultsData_4 = collectionData_4.slice();
                            }
                            resolve({
                                recordsTotal: collectionData_4.length,
                                data: resultsData_4
                            });
                        }
                        else {
                            resolve(undefined);
                        }
                    }, function (error) {
                        resolve(undefined);
                        throw new Error(error);
                    });
                }
            });
        });
    };
    ReportsService.prototype.getBuildingReportsWhenOnline = function (report_id, building_id, formData) {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                _this.apiService.get('reports/' + report_id + '/buildings/' + building_id + '/reports', formData).then(function (data) {
                    if (typeof data !== 'undefined') {
                        if (typeof data.success !== 'undefined') {
                            if (data.success === true) {
                                if (typeof data.data !== 'undefined') {
                                    resolve(data);
                                }
                                else {
                                    resolve(undefined);
                                }
                            }
                            else {
                                resolve(undefined);
                            }
                        }
                        else {
                            resolve(undefined);
                        }
                    }
                    else {
                        resolve(undefined);
                    }
                }).catch(function (error) {
                    resolve(undefined);
                    throw new Error(error);
                });
            }
            else {
                resolve(undefined);
            }
        });
    };
    ReportsService.prototype.getBuildingReport = function (report_id, building_id, building_report_id) {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                resolve(_this.getBuildingReportWhenOnline(report_id, building_id, building_report_id));
            }
            else {
                resolve(_this.getBuildingReportWhenOffline(report_id, building_id, building_report_id));
            }
        });
    };
    ReportsService.prototype.getBuildingReportWhenOffline = function (report_id, building_id, building_report_id) {
        var _this = this;
        return new Promise(function (resolve) {
            _this.indexedDBService.databaseReady.subscribe(function (event) {
                if (event) {
                    _this.indexedDBService.database.getByKey(_this.indexedDBService.tableReportsBuildingsReports, building_report_id).then(function (buildingReportDB) {
                        if (typeof buildingReportDB !== 'undefined') {
                            resolve(buildingReportDB);
                        }
                        else {
                            resolve(undefined);
                        }
                    }, function (error) {
                        resolve(undefined);
                        throw new Error(error);
                    });
                }
            });
        });
    };
    ReportsService.prototype.getBuildingReportWhenOnline = function (report_id, building_id, building_report_id) {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                _this.apiService.get('reports/' + report_id + '/buildings/' + building_id + '/reports/' + building_report_id).then(function (data) {
                    if (typeof data !== 'undefined') {
                        if (typeof data.success !== 'undefined') {
                            if (data.success === true) {
                                if (typeof data.data !== 'undefined') {
                                    if (typeof data.data[0] !== 'undefined') {
                                        resolve(data.data[0]);
                                    }
                                    else {
                                        resolve(undefined);
                                    }
                                }
                                else {
                                    resolve(undefined);
                                }
                            }
                            else {
                                resolve(undefined);
                            }
                        }
                        else {
                            resolve(undefined);
                        }
                    }
                    else {
                        resolve(undefined);
                    }
                }).catch(function (error) {
                    resolve(undefined);
                    throw new Error(error);
                });
            }
            else {
                resolve(undefined);
            }
        });
    };
    ReportsService.prototype.createBuildingReport = function (report_id, building_id, formData) {
        var _this = this;
        if (this.apiService.isOnline) {
            this.apiService.post('reports/' + report_id + '/buildings/' + building_id + '/reports', formData).then(function (data) {
                if (data.success === true) {
                    _this.snackbarService.success('Report saved.');
                    if (typeof data.data !== 'undefined') {
                        _this.indexedDBService.database.add(_this.indexedDBService.tableReportsBuildingsReports, data.data).then(function () {
                        }, function (error) { return new Error(error); });
                    }
                    _this.router.navigate(['/reports/' + report_id + '/buildings/' + building_id + '/reports']).then(function () {
                    });
                }
                else if (typeof data.error !== 'undefined') {
                    _this.errors.next(data.error);
                }
                else {
                    _this.snackbarService.error('An error occurred while loading the data, please try again.');
                }
            });
        }
        else {
            delete formData['photosContainer[]'];
            formData['id'] = moment().valueOf();
            formData['report_id'] = report_id;
            formData['building_id'] = building_id;
            formData['created'] = moment().valueOf();
            formData['created'] = moment().valueOf();
            formData['sequence'] = 1;
            if (typeof formData['warehouse_action_ids'] !== 'undefined') {
                formData['warehouse_action_ids'] = formData['warehouse_action_ids'].filter(function (data) { return data !== false; });
            }
            if (typeof formData['warehouse_type_ids'] !== 'undefined') {
                formData['warehouse_type_ids'] = formData['warehouse_type_ids'].filter(function (data) { return data !== false; });
            }
            if (typeof formData['warehouse_type_field_value'] !== 'undefined') {
                var tmp_1 = {};
                Object.keys(formData['warehouse_type_field_value']).map(function (key) {
                    if (formData['warehouse_type_field_value'][key] !== '') {
                        tmp_1[key.replace('[', '').replace(']', '')] = formData['warehouse_type_field_value'][key];
                    }
                });
                formData['warehouse_type_field_value'] = tmp_1;
            }
            var pushPhotos_1 = [], reportPhotos_1 = [];
            if (typeof formData['photos'] !== 'undefined') {
                if (formData['photos'].length) {
                    formData['photos'].forEach(function (photo) {
                        var id = crypto['randomUUID']();
                        pushPhotos_1.push({
                            id: id,
                            file: photo
                        });
                        reportPhotos_1.push({
                            filename: photo.name,
                            id: id,
                            offline: true
                        });
                    });
                    formData['photos'] = {
                        inspector: reportPhotos_1
                    };
                }
                if (typeof formData['photos']['inspector'] === 'undefined' && typeof formData['photos']['by_client'] === 'undefined') {
                    delete formData['photos'];
                }
            }
            if (typeof formData['color_code'] !== 'undefined') {
                if (formData['color_code'] === '0') {
                    formData['color'] = '#ED7C20';
                    formData['color_text'] = 'Oranje';
                }
                else if (formData['color_code'] === '1') {
                    formData['color'] = '#CC0000';
                    formData['color_text'] = 'Rood';
                }
                else if (formData['color_code'] === '2') {
                    formData['color'] = '#019342';
                    formData['color_text'] = 'Groen';
                }
            }
            this.indexedDBService.database.getAllFast(this.indexedDBService.tableReportsBuildingsReports).then(function (buildingReports) {
                if (typeof buildingReports !== 'undefined') {
                    buildingReports.forEach(function (buildingReport) {
                        if (buildingReport.report_id === report_id && buildingReport.building_id === building_id) {
                            if (typeof buildingReport.sequence !== 'undefined') {
                                if (formData['sequence'] <= buildingReport.sequence) {
                                    formData['sequence'] = parseInt(buildingReport.sequence, 10) + 1;
                                }
                            }
                        }
                    });
                    _this.indexedDBService.database.add(_this.indexedDBService.tableReportsBuildingsReports, formData).then(function () {
                        _this.snackbarService.success('Report saved.');
                        formData['indexedDBMethod'] = '1-create';
                        _this.indexedDBService.database.add(_this.indexedDBService.tableReportsBuildingsReportsPush, formData).then(function () {
                        }, function (error) { return new Error(error); });
                        if (pushPhotos_1.length) {
                            var reportPhotos2 = {
                                report_id: report_id,
                                building_id: building_id,
                                building_report_id: formData.id,
                                indexedDBMethod: '1-create',
                                photos: pushPhotos_1
                            };
                            _this.indexedDBService.database.add(_this.indexedDBService.tableReportsBuildingsReportsPhotos, reportPhotos2).then(function () {
                            }, function (error) { return new Error(error); });
                        }
                        _this.router.navigate(['/reports/' + report_id + '/buildings/' + building_id + '/reports']).then(function () {
                        });
                    }, function (error) { return new Error(error); });
                }
            });
        }
    };
    ReportsService.prototype.updateBuildingReport = function (report_id, building_id, building_report_id, formData) {
        var _this = this;
        if (this.apiService.isOnline) {
            this.apiService.post('reports/' + report_id + '/buildings/' + building_id + '/reports/' + building_report_id, formData).then(function (data) {
                if (data.success === true) {
                    _this.snackbarService.success('Report saved.');
                    if (typeof data.data !== 'undefined') {
                        _this.indexedDBService.database.update(_this.indexedDBService.tableReportsBuildingsReports, data.data).then(function () {
                        }, function (error) { return new Error(error); });
                    }
                    _this.router.navigate(['/reports/' + report_id + '/buildings/' + building_id + '/reports']).then(function () {
                    });
                }
                else if (typeof data.error !== 'undefined') {
                    _this.errors.next(data.error);
                }
                else {
                    _this.snackbarService.error('An error occurred while loading the data, please try again.');
                }
            });
        }
        else {
            this.indexedDBService.database.getByIndex(this.indexedDBService.tableReportsBuildingsReports, 'id', building_report_id).then(function (report) {
                delete formData['photosContainer[]'];
                if (typeof formData['warehouse_action_ids'] !== 'undefined') {
                    formData['warehouse_action_ids'] = formData['warehouse_action_ids'].filter(function (data) { return data !== false; });
                }
                if (typeof formData['warehouse_type_ids'] !== 'undefined') {
                    formData['warehouse_type_ids'] = formData['warehouse_type_ids'].filter(function (data) { return data !== false; });
                }
                if (typeof formData['warehouse_type_field_value'] !== 'undefined') {
                    var tmp_2 = {};
                    Object.keys(formData['warehouse_type_field_value']).map(function (key) {
                        if (formData['warehouse_type_field_value'][key] !== '') {
                            tmp_2[key.replace('[', '').replace(']', '')] = formData['warehouse_type_field_value'][key];
                        }
                    });
                    formData['warehouse_type_field_value'] = tmp_2;
                }
                var pushPhotos = [], reportPhotos = [];
                if (typeof formData['photos'] !== 'undefined') {
                    if (typeof report['photos'] !== 'undefined') {
                        if (typeof report['photos']['inspector'] !== 'undefined') {
                            reportPhotos = report['photos']['inspector'];
                        }
                    }
                    if (formData['photos'].length) {
                        formData['photos'].forEach(function (photo) {
                            var id = crypto['randomUUID']();
                            pushPhotos.push({
                                id: id,
                                file: photo
                            });
                            reportPhotos.push({
                                filename: photo.name,
                                id: id,
                                offline: true
                            });
                        });
                        formData['photos'] = {
                            inspector: reportPhotos
                        };
                    }
                    if (typeof formData['photos']['inspector'] === 'undefined' && typeof formData['photos']['by_client'] === 'undefined') {
                        delete formData['photos'];
                    }
                }
                if (typeof formData['color_code'] !== 'undefined') {
                    if (formData['color_code'] === '0') {
                        formData['color'] = '#ED7C20';
                        formData['color_text'] = 'Oranje';
                    }
                    else if (formData['color_code'] === '1') {
                        formData['color'] = '#CC0000';
                        formData['color_text'] = 'Rood';
                    }
                    else if (formData['color_code'] === '2') {
                        formData['color'] = '#019342';
                        formData['color_text'] = 'Groen';
                    }
                }
                if (typeof formData['client_fixed_status'] !== 'undefined') {
                    formData['is_resolved'] = formData['client_fixed_status'];
                }
                var newReport = __assign({}, report, formData);
                newReport['updated'] = moment().valueOf();
                _this.indexedDBService.database.update(_this.indexedDBService.tableReportsBuildingsReports, newReport).then(function () {
                    _this.snackbarService.success('Report saved.');
                    newReport['indexedDBMethod'] = '2-update';
                    _this.indexedDBService.database.add(_this.indexedDBService.tableReportsBuildingsReportsPush, newReport).then(function () {
                    }, function (error) { return new Error(error); });
                    if (pushPhotos.length) {
                        var reportPhotos2_1 = {
                            report_id: report_id,
                            building_id: building_id,
                            building_report_id: building_report_id,
                            indexedDBMethod: '1-create',
                            photos: pushPhotos
                        };
                        _this.indexedDBService.database.getByIndex(_this.indexedDBService.tableReportsBuildingsReportsPhotos, 'building_report_id', building_report_id).then(function (photos) {
                            if (typeof photos !== 'undefined') {
                                if (typeof photos.photos !== 'undefined') {
                                    photos.photos = photos.photos.concat(pushPhotos);
                                    _this.indexedDBService.database.update(_this.indexedDBService.tableReportsBuildingsReportsPhotos, photos).then(function () {
                                    }, function (error) { return new Error(error); });
                                }
                                else {
                                    _this.indexedDBService.database.add(_this.indexedDBService.tableReportsBuildingsReportsPhotos, reportPhotos2_1).then(function () {
                                    }, function (error) { return new Error(error); });
                                }
                            }
                            else {
                                _this.indexedDBService.database.add(_this.indexedDBService.tableReportsBuildingsReportsPhotos, reportPhotos2_1).then(function () {
                                }, function (error) { return new Error(error); });
                            }
                        }, function (error) { return new Error(error); });
                    }
                    _this.router.navigate(['/reports/' + report_id + '/buildings/' + building_id + '/reports']).then(function () {
                    });
                }, function (error) { return new Error(error); });
            }, function (error) { return new Error(error); });
        }
    };
    ReportsService.prototype.deleteBuildingReport = function (report_id, building_id, building_report_id) {
        var _this = this;
        return new Promise(function (resolve) {
            var dialogRef;
            dialogRef = _this.dialogService.open(SimpleModalDialogComponent);
            dialogRef.componentInstance.action_type = 'delete';
            dialogRef.componentInstance.text = 'Weet u zeker dat u deze contactpersoon wilt verwijderen?';
            dialogRef.beforeClosed().subscribe(function (response) {
                if (response === true) {
                    if (_this.apiService.isOnline) {
                        return _this.apiService.delete('reports/' + report_id + '/buildings/' + building_id + '/reports/' + building_report_id).then(function (data) {
                            if (data.success === true) {
                                _this.snackbarService.success('Report deleted.');
                                resolve(true);
                                _this.indexedDBService.database.delete(_this.indexedDBService.tableReportsBuildingsReports, building_report_id).then(function () {
                                }, function (error) { return new Error(error); });
                            }
                            else {
                                _this.snackbarService.error('An error occurred while loading the data, please try again.');
                                resolve(false);
                            }
                        });
                    }
                    else {
                        _this.indexedDBService.database.delete(_this.indexedDBService.tableReportsBuildingsReports, building_report_id).then(function () {
                            _this.snackbarService.success('Report deleted.');
                            _this.indexedDBService.database.add(_this.indexedDBService.tableReportsBuildingsReportsPush, {
                                id: building_report_id,
                                report_id: report_id,
                                building_id: building_id,
                                indexedDBMethod: '3-delete'
                            }).then(function () {
                                resolve(true);
                            }, function (error) {
                                resolve(undefined);
                                throw new Error(error);
                            });
                        }, function (error) {
                            resolve(undefined);
                            throw new Error(error);
                        });
                    }
                }
            });
        });
    };
    ReportsService.prototype.deleteBuildingReportPhoto = function (report_id, building_id, building_report_id, photo_id) {
        var _this = this;
        return new Promise(function (resolve) {
            var dialogRef;
            dialogRef = _this.dialogService.open(SimpleModalDialogComponent);
            dialogRef.componentInstance.action_type = 'delete';
            dialogRef.componentInstance.text = 'Are you sure you want to delete this photo?';
            dialogRef.beforeClosed().subscribe(function (response) {
                if (response === true) {
                    if (_this.apiService.isOnline) {
                        return _this.apiService.delete('reports/' + report_id + '/buildings/' + building_id + '/reports/' + building_report_id + '/delete-image/' + photo_id).then(function (data) {
                            if (data.success === true) {
                                _this.snackbarService.success('Photo deleted.');
                                resolve(true);
                            }
                            else {
                                _this.snackbarService.error('An error occurred while loading the data, please try again.');
                                resolve(false);
                            }
                        });
                    }
                    else {
                        _this.indexedDBService.database.getByIndex(_this.indexedDBService.tableReportsBuildingsReportsPhotos, 'building_report_id', building_report_id).then(function (report) {
                            var was_photo_removed = false, promise = [];
                            if (typeof report !== 'undefined') {
                                if (typeof report.photos !== 'undefined') {
                                    if (report.photos.length) {
                                        var photo = report.photos.filter(function (data) { return data.id === photo_id; });
                                        if (photo.length) {
                                            photo = report.photos.indexOf(photo[0]);
                                            report.photos.splice(photo, 1);
                                            if (report.photos.length) {
                                                promise.push(_this.indexedDBService.database.update(_this.indexedDBService.tableReportsBuildingsReportsPhotos, report).then(function () {
                                                    was_photo_removed = true;
                                                }, function (error) { return new Error(error); }));
                                            }
                                            else {
                                                promise.push(_this.indexedDBService.database.delete(_this.indexedDBService.tableReportsBuildingsReportsPhotos, 'building_report_id', building_report_id).then(function () {
                                                    was_photo_removed = true;
                                                }, function (error) { return new Error(error); }));
                                            }
                                            _this.indexedDBService.database.getByIndex(_this.indexedDBService.tableReportsBuildingsReports, 'id', building_report_id).then(function (report2) {
                                                if (typeof report2 !== 'undefined') {
                                                    if (typeof report2.photos !== 'undefined') {
                                                        if (typeof report2.photos.inspector !== 'undefined') {
                                                            if (report2.photos.inspector.length) {
                                                                var photo2 = report2.photos.inspector.filter(function (data) { return data.id === photo_id; });
                                                                if (photo2.length) {
                                                                    photo2 = report2.photos.inspector.indexOf(photo2[0]);
                                                                    report2.photos.inspector.splice(photo2, 1);
                                                                    _this.indexedDBService.database.update(_this.indexedDBService.tableReportsBuildingsReports, report2).then(function () {
                                                                        _this.indexedDBService.database.getAllFast(_this.indexedDBService.tableReportsBuildingsReportsPush).then(function (reports3) {
                                                                            if (typeof reports3 !== 'undefined') {
                                                                                var report3 = reports3.filter(function (data) { return data.id === building_report_id; })[0];
                                                                                if (typeof report3.photos !== 'undefined') {
                                                                                    if (typeof report3.photos.inspector !== 'undefined') {
                                                                                        if (report3.photos.inspector.length) {
                                                                                            var photo3 = report3.photos.inspector.filter(function (data) { return data.id === photo_id; });
                                                                                            if (photo3.length) {
                                                                                                photo3 = report3.photos.inspector.indexOf(photo3[0]);
                                                                                                report3.photos.inspector.splice(photo3, 1);
                                                                                                _this.indexedDBService.database.update(_this.indexedDBService.tableReportsBuildingsReportsPush, report3).then(function () {
                                                                                                }, function (error) { return new Error(error); });
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        });
                                                                    }, function (error) { return new Error(error); });
                                                                }
                                                            }
                                                        }
                                                    }
                                                }
                                            });
                                            _this.snackbarService.success('Photo deleted.');
                                        }
                                    }
                                }
                            }
                            Promise.all(promise).then(function () {
                                if (was_photo_removed === false) {
                                    var formData = {
                                        id: photo_id,
                                        report_id: report_id,
                                        building_id: building_id,
                                        building_report_id: building_report_id,
                                        indexedDBMethod: '3-delete'
                                    };
                                    _this.indexedDBService.database.add(_this.indexedDBService.tableReportsBuildingsReportsPhotos, formData).then(function () {
                                        _this.snackbarService.success('Photo deleted.');
                                        resolve(true);
                                    }, function (error) { return new Error(error); });
                                }
                            });
                            resolve(true);
                        }, function (error) { return new Error(error); });
                    }
                }
            });
        });
    };
    ReportsService.prototype.getOfflinePhotos = function (report_id, building_id, building_report_id, photo_id) {
        var _this = this;
        return new Promise(function (resolve) {
            _this.indexedDBService.database.getAll(_this.indexedDBService.tableReportsBuildingsReportsPhotos).then(function (reports) {
                reports.forEach(function (report) {
                    if (report.report_id === report_id && report.building_id === building_id && report.building_report_id === building_report_id && typeof report.photos !== 'undefined') {
                        if (typeof report.photos !== 'undefined') {
                            var photo = report.photos.filter(function (data) { return data.id === photo_id; })[0], file = photo.file, reader_1 = new FileReader();
                            reader_1.onloadend = function () {
                                resolve(reader_1.result);
                            };
                            reader_1.readAsDataURL(file);
                        }
                    }
                });
            }, function (error) {
                resolve(undefined);
                throw new Error(error);
            });
        });
    };
    ReportsService.prototype.getCompliances = function (report_id) {
        var _this = this;
        return new Promise(function (resolve) {
            _this.indexedDBService.databaseReady.subscribe(function (event) {
                if (event) {
                    _this.indexedDBService.database.getAllFast(_this.indexedDBService.tableReportsCompliances).then(function (reportCompliancesDB) {
                        var compliances = [];
                        var _loop_1 = function (i) {
                            var compliance = {
                                weekday: i,
                                day: (i === 0 ? 'Monday' : (i === 1 ? 'Tuesday' : (i === 2 ? 'Wednesday' : (i === 3 ? 'Thursday' : (i === 4 ? 'Friday' : (i === 5 ? 'Saturday' : (i === 6 ? 'Sunday' : '-'))))))),
                                status: false
                            };
                            if (typeof reportCompliancesDB !== 'undefined') {
                                reportCompliancesDB.forEach(function (compliances) {
                                    if (compliances.report_id === report_id && compliances.weekday === i) {
                                        compliance.status = true;
                                    }
                                });
                            }
                            compliances.push(compliance);
                        };
                        for (var i = 0; i < 7; i++) {
                            _loop_1(i);
                        }
                        resolve(compliances);
                    }, function (error) {
                        resolve(undefined);
                        throw new Error(error);
                    });
                }
            });
        });
    };
    ReportsService.prototype.getWeekdayCompliance = function (report_id, weekday, compliance_id) {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                resolve(_this.getWeekdayComplianceWhenOnline(report_id, weekday, compliance_id));
            }
            else {
                resolve(_this.getWeekdayComplianceWhenOffline(report_id, compliance_id));
            }
        });
    };
    ReportsService.prototype.getWeekdayComplianceWhenOffline = function (report_id, compliance_id) {
        var _this = this;
        return new Promise(function (resolve) {
            _this.indexedDBService.databaseReady.subscribe(function (event) {
                if (event) {
                    _this.indexedDBService.database.getByKey(_this.indexedDBService.tableReportsCompliances, compliance_id).then(function (reportComplianceDB) {
                        if (typeof reportComplianceDB !== 'undefined') {
                            resolve(reportComplianceDB);
                        }
                        else {
                            resolve(undefined);
                        }
                    }, function (error) {
                        resolve(undefined);
                        throw new Error(error);
                    });
                }
            });
        });
    };
    ReportsService.prototype.getWeekdayComplianceWhenOnline = function (report_id, weekday, compliance_id) {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                _this.apiService.get('reports/' + report_id + '/compliances/' + weekday + '/' + compliance_id).then(function (data) {
                    if (typeof data !== 'undefined') {
                        if (typeof data.success !== 'undefined') {
                            if (data.success === true) {
                                if (typeof data.data !== 'undefined') {
                                    if (typeof data.data[0] !== 'undefined') {
                                        resolve(data.data[0]);
                                    }
                                    else {
                                        resolve(undefined);
                                    }
                                }
                                else {
                                    resolve(undefined);
                                }
                            }
                            else {
                                resolve(undefined);
                            }
                        }
                        else {
                            resolve(undefined);
                        }
                    }
                    else {
                        resolve(undefined);
                    }
                }).catch(function (error) {
                    resolve(undefined);
                    throw new Error(error);
                });
            }
            else {
                resolve(undefined);
            }
        });
    };
    ReportsService.prototype.getWeekdayCompliances = function (report_id, weekday) {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                resolve(_this.getWeekdayCompliancesWhenOnline(report_id, weekday));
            }
            else {
                resolve(_this.getWeekdayCompliancesWhenOffline(report_id, weekday));
            }
        });
    };
    ReportsService.prototype.getWeekdayCompliancesWhenOffline = function (report_id, weekday) {
        var _this = this;
        return new Promise(function (resolve) {
            _this.indexedDBService.databaseReady.subscribe(function (event) {
                if (event) {
                    _this.indexedDBService.database.getAllFast(_this.indexedDBService.tableReportsCompliances).then(function (reportComplianceDB) {
                        var reportCompliances = [];
                        if (typeof reportComplianceDB !== 'undefined') {
                            reportComplianceDB.forEach(function (compliance) {
                                if (compliance.report_id === report_id && compliance.weekday === weekday) {
                                    reportCompliances.push(compliance);
                                }
                            });
                            resolve(reportCompliances);
                        }
                        else {
                            resolve(undefined);
                        }
                    }, function (error) {
                        resolve(undefined);
                        throw new Error(error);
                    });
                }
            });
        });
    };
    ReportsService.prototype.getWeekdayCompliancesWhenOnline = function (report_id, weekday) {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                _this.apiService.get('reports/' + report_id + '/compliances/' + weekday).then(function (data) {
                    if (typeof data !== 'undefined') {
                        if (typeof data.success !== 'undefined') {
                            if (data.success === true) {
                                if (typeof data.data !== 'undefined') {
                                    if (typeof data.data[0] !== 'undefined') {
                                        resolve(data.data);
                                    }
                                    else {
                                        resolve(undefined);
                                    }
                                }
                                else {
                                    resolve(undefined);
                                }
                            }
                            else {
                                resolve(undefined);
                            }
                        }
                        else {
                            resolve(undefined);
                        }
                    }
                    else {
                        resolve(undefined);
                    }
                }).catch(function (error) {
                    resolve(undefined);
                    throw new Error(error);
                });
            }
            else {
                resolve(undefined);
            }
        });
    };
    ReportsService.prototype.createCompliance = function (report_id, weekday, formData) {
        var _this = this;
        if (this.apiService.isOnline) {
            return this.apiService.post('reports/' + report_id + '/compliances/' + weekday, formData).then(function (data) {
                if (data.success === true) {
                    _this.snackbarService.success('Compliance saved.');
                    if (typeof data.data !== 'undefined') {
                        return _this.indexedDBService.database.add(_this.indexedDBService.tableReportsCompliances, data.data).then(function () {
                            return true;
                        }, function (error) {
                            new Error(error);
                            return true;
                        });
                    }
                    else {
                        return true;
                    }
                }
                else if (typeof data.error !== 'undefined') {
                    _this.errors.next(data.error);
                    return false;
                }
                else {
                    _this.snackbarService.error('An error occurred while loading the data, please try again.');
                    return false;
                }
            });
        }
        else {
            formData['id'] = moment().valueOf();
            formData['report_id'] = report_id;
            formData['weekday'] = weekday;
            formData['created'] = moment().valueOf();
            delete formData['query'];
            return this.indexedDBService.database.add(this.indexedDBService.tableReportsCompliances, formData).then(function () {
                _this.snackbarService.success('Compliance saved.');
                formData['indexedDBMethod'] = '1-create';
                return _this.indexedDBService.database.add(_this.indexedDBService.tableReportsCompliancesPush, formData).then(function () {
                    return true;
                }, function (error) {
                    new Error(error);
                    return false;
                });
            }, function (error) {
                new Error(error);
                return false;
            });
        }
    };
    ReportsService.prototype.updateCompliance = function (report_id, weekday, daily_id, formData) {
        var _this = this;
        if (this.apiService.isOnline) {
            return this.apiService.post('reports/' + report_id + '/compliances/' + weekday + '/' + daily_id, formData).then(function (data) {
                if (data.success === true) {
                    _this.snackbarService.success('Compliance saved.');
                    if (typeof data.data !== 'undefined') {
                        return _this.indexedDBService.database.update(_this.indexedDBService.tableReportsCompliances, data.data).then(function () {
                            return true;
                        }, function (error) {
                            new Error(error);
                            return false;
                        });
                    }
                    else {
                        return true;
                    }
                }
                else if (typeof data.error !== 'undefined') {
                    _this.errors.next(data.error);
                    return false;
                }
                else {
                    _this.snackbarService.error('An error occurred while loading the data, please try again.');
                    return false;
                }
            });
        }
        else {
            return this.indexedDBService.database.getByIndex(this.indexedDBService.tableReportsCompliances, 'id', daily_id).then(function (compliance) {
                delete formData['query'];
                var newCompliance = __assign({}, compliance, formData);
                newCompliance['updated'] = moment().valueOf();
                return _this.indexedDBService.database.update(_this.indexedDBService.tableReportsCompliances, newCompliance).then(function () {
                    _this.snackbarService.success('Compliance saved.');
                    newCompliance['indexedDBMethod'] = '2-update';
                    return _this.indexedDBService.database.add(_this.indexedDBService.tableReportsCompliancesPush, newCompliance).then(function () {
                        return true;
                    }, function (error) {
                        new Error(error);
                        return false;
                    });
                }, function (error) {
                    new Error(error);
                    return false;
                });
            }, function (error) {
                new Error(error);
                return false;
            });
        }
    };
    ReportsService.prototype.deleteCompliance = function (report_id, weekday, daily_id) {
        var _this = this;
        return new Promise(function (resolve) {
            var dialogRef;
            dialogRef = _this.dialogService.open(SimpleModalDialogComponent);
            dialogRef.componentInstance.action_type = 'delete';
            dialogRef.componentInstance.text = 'Are you sure you want to delete this compliance?';
            dialogRef.beforeClosed().subscribe(function (response) {
                if (response === true) {
                    if (_this.apiService.isOnline) {
                        return _this.apiService.delete('reports/' + report_id + '/compliances/' + weekday + '/' + daily_id).then(function (data) {
                            if (data.success === true) {
                                _this.snackbarService.success('Compliance deleted.');
                                _this.indexedDBService.database.delete(_this.indexedDBService.tableReportsCompliances, daily_id).then(function () {
                                    resolve(true);
                                }, function (error) {
                                    resolve(true);
                                    throw new Error(error);
                                });
                            }
                            else {
                                _this.snackbarService.error('Error occurred while deleting compliance.');
                                resolve(false);
                            }
                        }, function (error) {
                            resolve(undefined);
                            throw new Error(error);
                        });
                    }
                    else {
                        _this.indexedDBService.database.delete(_this.indexedDBService.tableReportsCompliances, daily_id).then(function () {
                            _this.snackbarService.success('Compliance deleted.');
                            _this.indexedDBService.database.add(_this.indexedDBService.tableReportsCompliancesPush, {
                                id: daily_id,
                                report_id: report_id,
                                weekday: weekday,
                                indexedDBMethod: '3-delete'
                            }).then(function () {
                                resolve(true);
                            }, function (error) {
                                resolve(undefined);
                                throw new Error(error);
                            });
                        }, function (error) {
                            resolve(undefined);
                            throw new Error(error);
                        });
                    }
                }
            });
        });
    };
    ReportsService.prototype.getPalletReport = function (report_id, pallet_report_id) {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                resolve(_this.getPalletReportWhenOnline(report_id, pallet_report_id));
            }
            else {
                resolve(_this.getPalletReportWhenOffline(report_id, pallet_report_id));
            }
        });
    };
    ReportsService.prototype.getPalletReportWhenOffline = function (report_id, pallet_report_id) {
        var _this = this;
        return new Promise(function (resolve) {
            _this.indexedDBService.databaseReady.subscribe(function (event) {
                if (event) {
                    _this.indexedDBService.database.getByKey(_this.indexedDBService.tableReportsIkeaPalletReports, pallet_report_id).then(function (palletReportDB) {
                        if (typeof palletReportDB !== 'undefined') {
                            resolve(palletReportDB);
                        }
                        else {
                            resolve(undefined);
                        }
                    }, function (error) {
                        resolve(undefined);
                        throw new Error(error);
                    });
                }
            });
        });
    };
    ReportsService.prototype.getPalletReportWhenOnline = function (report_id, pallet_report_id) {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                _this.apiService.get('reports/' + report_id + '/pallet-reports/' + pallet_report_id).then(function (data) {
                    if (typeof data !== 'undefined') {
                        if (typeof data.success !== 'undefined') {
                            if (data.success === true) {
                                if (typeof data.data !== 'undefined') {
                                    if (typeof data.data[0] !== 'undefined') {
                                        resolve(data.data[0]);
                                    }
                                    else {
                                        resolve(undefined);
                                    }
                                }
                                else {
                                    resolve(undefined);
                                }
                            }
                            else {
                                resolve(undefined);
                            }
                        }
                        else {
                            resolve(undefined);
                        }
                    }
                    else {
                        resolve(undefined);
                    }
                }).catch(function (error) {
                    resolve(undefined);
                    throw new Error(error);
                });
            }
            else {
                resolve(undefined);
            }
        });
    };
    ReportsService.prototype.getPalletReports = function (report_id) {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                resolve(_this.getPalletReportsWhenOnline(report_id));
            }
            else {
                resolve(_this.getPalletReportsWhenOffline(report_id));
            }
        });
    };
    ReportsService.prototype.getPalletReportsWhenOffline = function (report_id) {
        var _this = this;
        return new Promise(function (resolve) {
            _this.indexedDBService.databaseReady.subscribe(function (event) {
                if (event) {
                    _this.indexedDBService.database.getAllFast(_this.indexedDBService.tableReportsIkeaPalletReports).then(function (palletReportsDB) {
                        var palletReports = [];
                        if (typeof palletReportsDB !== 'undefined') {
                            palletReportsDB.forEach(function (palletReport) {
                                if (palletReport.report_id === report_id) {
                                    palletReports.push(palletReport);
                                }
                            });
                            resolve(palletReports);
                        }
                        else {
                            resolve(undefined);
                        }
                    }, function (error) {
                        resolve(undefined);
                        throw new Error(error);
                    });
                }
            });
        });
    };
    ReportsService.prototype.getPalletReportsWhenOnline = function (report_id) {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                _this.apiService.get('reports/' + report_id + '/pallet-reports/').then(function (data) {
                    if (typeof data !== 'undefined') {
                        if (typeof data.success !== 'undefined') {
                            if (data.success === true) {
                                if (typeof data.data !== 'undefined') {
                                    if (typeof data.data[0] !== 'undefined') {
                                        resolve(data.data);
                                    }
                                    else {
                                        resolve(undefined);
                                    }
                                }
                                else {
                                    resolve(undefined);
                                }
                            }
                            else {
                                resolve(undefined);
                            }
                        }
                        else {
                            resolve(undefined);
                        }
                    }
                    else {
                        resolve(undefined);
                    }
                }).catch(function (error) {
                    resolve(undefined);
                    throw new Error(error);
                });
            }
            else {
                resolve(undefined);
            }
        });
    };
    ReportsService.prototype.createPalletReport = function (report_id, formData) {
        var _this = this;
        if (this.apiService.isOnline) {
            this.apiService.post('reports/' + report_id + '/pallet-reports', formData).then(function (data) {
                if (data.success === true) {
                    _this.snackbarService.success('Pallet report saved.');
                    if (typeof data.data !== 'undefined') {
                        _this.indexedDBService.database.add(_this.indexedDBService.tableReportsIkeaPalletReports, data.data).then(function () {
                            _this.router.navigate(['/reports/' + report_id + '/view']).then(function () { });
                        }, function (error) { return new Error(error); });
                    }
                }
                else if (typeof data.error !== 'undefined') {
                    _this.errors.next(data.error);
                }
                else {
                    _this.snackbarService.error('An error occurred while loading the data, please try again.');
                }
            });
        }
        else {
            formData['id'] = moment().valueOf();
            formData['report_id'] = report_id;
            formData['created'] = moment().valueOf();
            delete formData['query'];
            this.indexedDBService.database.add(this.indexedDBService.tableReportsIkeaPalletReports, formData).then(function () {
                _this.snackbarService.success('Pallet report saved.');
                formData['indexedDBMethod'] = '1-create';
                _this.indexedDBService.database.add(_this.indexedDBService.tableReportsIkeaPalletReportsPush, formData).then(function () {
                    _this.router.navigate(['/reports/' + report_id + '/view']).then(function () { });
                }, function (error) { return new Error(error); });
            }, function (error) { return new Error(error); });
        }
    };
    ReportsService.prototype.updatePalletReport = function (report_id, pallet_report_id, formData) {
        var _this = this;
        if (this.apiService.isOnline) {
            this.apiService.post('reports/' + report_id + '/pallet-reports/' + pallet_report_id, formData).then(function (data) {
                if (data.success === true) {
                    _this.snackbarService.success('Pallet report saved.');
                    if (typeof data.data !== 'undefined') {
                        _this.indexedDBService.database.update(_this.indexedDBService.tableReportsIkeaPalletReports, data.data).then(function () {
                            _this.router.navigate(['/reports/' + report_id + '/view']).then(function () { });
                        }, function (error) { return new Error(error); });
                    }
                }
                else if (typeof data.error !== 'undefined') {
                    _this.errors.next(data.error);
                }
                else {
                    _this.snackbarService.error('An error occurred while loading the data, please try again.');
                }
            });
        }
        else {
            this.indexedDBService.database.getByIndex(this.indexedDBService.tableReportsIkeaPalletReports, pallet_report_id).then(function (pallet_report) {
                delete formData['query'];
                var newPalletReport = __assign({}, pallet_report, formData);
                newPalletReport['updated'] = moment().valueOf();
                _this.indexedDBService.database.update(_this.indexedDBService.tableReportsIkeaPalletReports, newPalletReport).then(function () {
                    _this.snackbarService.success('Pallet report saved.');
                    newPalletReport['indexedDBMethod'] = '2-update';
                    _this.indexedDBService.database.add(_this.indexedDBService.tableReportsIkeaPalletReportsPush, newPalletReport).then(function () {
                        _this.router.navigate(['/reports/' + report_id + '/view']).then(function () { });
                    }, function (error) { return new Error(error); });
                }, function (error) { return new Error(error); });
            }, function (error) { return new Error(error); });
        }
    };
    ReportsService.prototype.deletePalletReport = function (report_id, pallet_report_id) {
        var _this = this;
        return new Promise(function (resolve) {
            var dialogRef;
            dialogRef = _this.dialogService.open(SimpleModalDialogComponent);
            dialogRef.componentInstance.action_type = 'delete';
            dialogRef.componentInstance.text = 'Are you sure you want to delete this pallet report?';
            dialogRef.beforeClosed().subscribe(function (response) {
                if (response === true) {
                    if (_this.apiService.isOnline) {
                        return _this.apiService.delete('reports/' + report_id + '/pallet-reports/' + pallet_report_id).then(function (data) {
                            if (data.success === true) {
                                _this.snackbarService.success('Pallet report deleted.');
                                _this.indexedDBService.database.delete(_this.indexedDBService.tableReportsIkeaPalletReports, pallet_report_id).then(function () {
                                    resolve(true);
                                }, function (error) {
                                    resolve(true);
                                    throw new Error(error);
                                });
                            }
                            else {
                                _this.snackbarService.error('Error occurred while deleting pallet report.');
                                resolve(false);
                            }
                        }, function (error) {
                            resolve(undefined);
                            throw new Error(error);
                        });
                    }
                    else {
                        _this.indexedDBService.database.delete(_this.indexedDBService.tableReportsIkeaPalletReports, pallet_report_id).then(function () {
                            _this.snackbarService.success('Pallet report deleted.');
                            _this.indexedDBService.database.add(_this.indexedDBService.tableReportsIkeaPalletReportsPush, {
                                id: pallet_report_id,
                                report_id: report_id,
                                indexedDBMethod: '3-delete'
                            }).then(function () {
                                resolve(true);
                            }, function (error) {
                                resolve(undefined);
                                throw new Error(error);
                            });
                        }, function (error) {
                            resolve(undefined);
                            throw new Error(error);
                        });
                    }
                }
            });
        });
    };
    ReportsService.prototype.downloadFile = function (url, filename) {
        if (this.apiService.isOnline) {
            if (typeof url !== 'undefined') {
                this.apiService.download(url, filename);
            }
            else {
                this.snackbarService.error('Invalid link, cannot be downloaded.');
            }
        }
        else {
            this.snackbarService.warning('This function is only available when there is an internet connection.');
        }
    };
    ReportsService.prototype.syncCategories = function () {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                _this.apiService.get('reports/sync/elements/last-update-date').then(function (server_date) {
                    if (server_date.success === true) {
                        if (typeof server_date.data !== 'undefined') {
                            if (typeof server_date.data.updated !== 'undefined') {
                                _this.apiService.get('reports/sync/elements', {
                                    start: 0,
                                    length: -1
                                }).then(function (data) {
                                    if (typeof data.success !== 'undefined') {
                                        if (data.success === true) {
                                            if (typeof data.data !== 'undefined') {
                                                return _this.indexedDBService.database.updateBulk(_this.indexedDBService.tableReportsCategories, data.data)
                                                    .then(function () {
                                                    return _this.indexedDBService.database.update(_this.indexedDBService.tableDataSync, {
                                                        name: _this.indexedDBService.tableReportsCategories,
                                                        updated: server_date.data.updated
                                                    })
                                                        .then(function () { return resolve(data.data); })
                                                        .catch(function (error) { return new Error(error); });
                                                })
                                                    .catch(function (error) { return new Error(error); });
                                            }
                                            else {
                                                resolve(undefined);
                                            }
                                        }
                                        else {
                                            resolve(undefined);
                                        }
                                    }
                                    else {
                                        resolve(undefined);
                                    }
                                }).catch(function (error) { return new Error(error); });
                            }
                            else {
                                resolve(undefined);
                            }
                        }
                        else {
                            resolve(undefined);
                        }
                    }
                    else {
                        resolve(undefined);
                    }
                });
            }
            else {
                resolve(undefined);
            }
        });
    };
    ReportsService.prototype.syncSubcategories = function () {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                _this.apiService.get('reports/sync/elements/subelements/last-update-date').then(function (server_date) {
                    if (server_date.success === true) {
                        if (typeof server_date.data !== 'undefined') {
                            if (typeof server_date.data.updated !== 'undefined') {
                                _this.apiService.get('reports/sync/elements/subelements', {
                                    start: 0,
                                    length: -1
                                }).then(function (data) {
                                    if (typeof data.success !== 'undefined') {
                                        if (data.success === true) {
                                            if (typeof data.data !== 'undefined') {
                                                return _this.indexedDBService.database.updateBulk(_this.indexedDBService.tableReportsSubcategories, data.data)
                                                    .then(function () {
                                                    return _this.indexedDBService.database.update(_this.indexedDBService.tableDataSync, {
                                                        name: _this.indexedDBService.tableReportsSubcategories,
                                                        updated: server_date.data.updated
                                                    })
                                                        .then(function () { return resolve(data.data); })
                                                        .catch(function (error) { return new Error(error); });
                                                })
                                                    .catch(function (error) { return new Error(error); });
                                            }
                                            else {
                                                resolve(undefined);
                                            }
                                        }
                                        else {
                                            resolve(undefined);
                                        }
                                    }
                                    else {
                                        resolve(undefined);
                                    }
                                }).catch(function (error) { return new Error(error); });
                            }
                            else {
                                resolve(undefined);
                            }
                        }
                        else {
                            resolve(undefined);
                        }
                    }
                    else {
                        resolve(undefined);
                    }
                });
            }
            else {
                resolve(undefined);
            }
        });
    };
    ReportsService.prototype.syncTypes = function () {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                _this.apiService.get('reports/sync/elements/subelements/types/last-update-date').then(function (server_date) {
                    if (server_date.success === true) {
                        if (typeof server_date.data !== 'undefined') {
                            if (typeof server_date.data.updated !== 'undefined') {
                                _this.apiService.get('reports/sync/elements/subelements/types', {
                                    start: 0,
                                    length: -1
                                }).then(function (data) {
                                    if (typeof data.success !== 'undefined') {
                                        if (data.success === true) {
                                            if (typeof data.data !== 'undefined') {
                                                var filtered_1 = [];
                                                data.data.forEach(function (type) {
                                                    type.type_name = type.type_name.replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&amp;/g, '&');
                                                    filtered_1.push(type);
                                                });
                                                if (filtered_1.length) {
                                                    return _this.indexedDBService.database.updateBulk(_this.indexedDBService.tableReportsTypes, filtered_1)
                                                        .then(function () {
                                                        return _this.indexedDBService.database.update(_this.indexedDBService.tableDataSync, {
                                                            name: _this.indexedDBService.tableReportsTypes,
                                                            updated: server_date.data.updated
                                                        })
                                                            .then(function () { return resolve(data.data); })
                                                            .catch(function (error) { return new Error(error); });
                                                    })
                                                        .catch(function (error) { return new Error(error); });
                                                }
                                                else {
                                                    resolve(undefined);
                                                }
                                            }
                                            else {
                                                resolve(undefined);
                                            }
                                        }
                                        else {
                                            resolve(undefined);
                                        }
                                    }
                                    else {
                                        resolve(undefined);
                                    }
                                }).catch(function (error) { return new Error(error); });
                            }
                            else {
                                resolve(undefined);
                            }
                        }
                        else {
                            resolve(undefined);
                        }
                    }
                    else {
                        resolve(undefined);
                    }
                });
            }
            else {
                resolve(undefined);
            }
        });
    };
    ReportsService.prototype.syncActions = function () {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                _this.apiService.get('reports/sync/elements/subelements/actions/last-update-date').then(function (server_date) {
                    if (server_date.success === true) {
                        if (typeof server_date.data !== 'undefined') {
                            if (typeof server_date.data.updated !== 'undefined') {
                                _this.apiService.get('reports/sync/elements/subelements/actions', {
                                    start: 0,
                                    length: -1
                                }).then(function (data) {
                                    if (typeof data.success !== 'undefined') {
                                        if (data.success === true) {
                                            if (typeof data.data !== 'undefined') {
                                                var filtered_2 = [];
                                                data.data.forEach(function (action) {
                                                    action.action_name = action.action_name.replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace(/&amp;/g, '&');
                                                    filtered_2.push(action);
                                                });
                                                if (filtered_2.length) {
                                                    return _this.indexedDBService.database.updateBulk(_this.indexedDBService.tableReportsActions, filtered_2)
                                                        .then(function () {
                                                        return _this.indexedDBService.database.update(_this.indexedDBService.tableDataSync, {
                                                            name: 'reports_warehouse_actions',
                                                            updated: server_date.data.updated
                                                        })
                                                            .then(function () { return resolve(filtered_2); })
                                                            .catch(function (error) { return new Error(error); });
                                                    })
                                                        .catch(function (error) { return new Error(error); });
                                                }
                                                else {
                                                    resolve(undefined);
                                                }
                                            }
                                            else {
                                                resolve(undefined);
                                            }
                                        }
                                        else {
                                            resolve(undefined);
                                        }
                                    }
                                    else {
                                        resolve(undefined);
                                    }
                                }).catch(function (error) { return new Error(error); });
                            }
                            else {
                                resolve(undefined);
                            }
                        }
                        else {
                            resolve(undefined);
                        }
                    }
                    else {
                        resolve(undefined);
                    }
                });
            }
            else {
                resolve(undefined);
            }
        });
    };
    ReportsService.prototype.syncPalletOptions = function () {
        var _this = this;
        return new Promise(function (resolve) {
            if (_this.apiService.isOnline) {
                _this.apiService.get('reports/sync/pallet-options/last-update-date').then(function (server_date) {
                    if (server_date.success === true) {
                        if (typeof server_date.data !== 'undefined') {
                            if (typeof server_date.data.updated !== 'undefined') {
                                _this.apiService.get('reports/sync/pallet-options', {
                                    start: 0,
                                    length: -1
                                }).then(function (data) {
                                    if (typeof data.success !== 'undefined') {
                                        if (data.success === true) {
                                            if (typeof data.data !== 'undefined') {
                                                return _this.indexedDBService.database.updateBulk('reports_ikea_pallet_optitons', data.data)
                                                    .then(function () {
                                                    return _this.indexedDBService.database.update(_this.indexedDBService.tableDataSync, {
                                                        name: _this.indexedDBService.tableReportsIkeaPalletOptions,
                                                        updated: server_date.data.updated
                                                    })
                                                        .then(function () { return resolve(data.data); })
                                                        .catch(function (error) { return new Error(error); });
                                                })
                                                    .catch(function (error) { return new Error(error); });
                                            }
                                            else {
                                                resolve(undefined);
                                            }
                                        }
                                        else {
                                            resolve(undefined);
                                        }
                                    }
                                    else {
                                        resolve(undefined);
                                    }
                                }).catch(function (error) { return new Error(error); });
                            }
                            else {
                                resolve(undefined);
                            }
                        }
                        else {
                            resolve(undefined);
                        }
                    }
                    else {
                        resolve(undefined);
                    }
                });
            }
            else {
                resolve(undefined);
            }
        });
    };
    ReportsService.prototype.cleanupAfterReportDeletion = function (reportsIndexes) {
        var _this = this;
        reportsIndexes.forEach(function (report_id) {
            _this.deleteReportAttachments(report_id).then(function () {
            });
            _this.deleteReportBuildings(report_id).then(function () {
            });
            _this.deleteReportBuildingsReports(report_id).then(function () {
            });
            _this.deleteReportComments(report_id).then(function () {
            });
            _this.indexedDBService.database.openCursorWithIndex(_this.indexedDBService.tableReportsSendToClient, 'report_id', function (event) {
                var cursor = event.target.result;
                if (cursor) {
                    if (cursor.value.report_id === report_id) {
                        _this.indexedDBService.database.delete(_this.indexedDBService.tableReportsSendToClient, cursor.value.id).then(function () {
                        }, function (error) { return new Error(error); });
                    }
                }
            }).then(function () {
            }, function (error) { return new Error(error); });
        });
    };
    ReportsService.prototype.deleteReportAttachments = function (report_id) {
        var _this = this;
        return new Promise(function (resolve) {
            var promises = [];
            promises.push(_this.indexedDBService.database.getAllFast(_this.indexedDBService.tableReportsAttachments)
                .then(function (reports_attachments) {
                var filtered = [];
                reports_attachments.forEach(function (report_attachment) {
                    if (report_attachment.report_id === report_id) {
                        filtered.push(report_attachment.id);
                    }
                });
                if (filtered.length) {
                    return _this.indexedDBService.database.deleteBulk(_this.indexedDBService.tableReportsAttachments, filtered)
                        .then(function () { return true; })
                        .catch(function (error) { return new Error(error); });
                }
                return true;
            })
                .catch(function (error) { return new Error(error); }));
            promises.push(_this.indexedDBService.database.getAllFast(_this.indexedDBService.tableReportsAttachmentsPush)
                .then(function (reports_attachments) {
                var filtered = [];
                reports_attachments.forEach(function (report_attachment) {
                    if (report_attachment.report_id === report_id) {
                        filtered.push(report_attachment.entryId);
                    }
                });
                if (filtered.length) {
                    return _this.indexedDBService.database.deleteBulk(_this.indexedDBService.tableReportsAttachmentsPush, filtered)
                        .then(function () { return true; })
                        .catch(function (error) { return new Error(error); });
                }
                return true;
            })
                .catch(function (error) { return new Error(error); }));
            return Promise.all(promises).then(function () { return resolve(); });
        });
    };
    ReportsService.prototype.deleteReportBuildings = function (report_id) {
        var _this = this;
        return new Promise(function (resolve) {
            var promises = [];
            promises.push(_this.indexedDBService.database.getAllFast(_this.indexedDBService.tableReportsBuildings)
                .then(function (report_buildings) {
                var filtered = [];
                report_buildings.forEach(function (report_building) {
                    if (report_building.report_id === report_id) {
                        filtered.push(report_building.id);
                    }
                });
                if (filtered.length) {
                    return _this.indexedDBService.database.deleteBulk(_this.indexedDBService.tableReportsBuildings, filtered)
                        .then(function () { return true; })
                        .catch(function (error) { return new Error(error); });
                }
                return true;
            })
                .catch(function (error) { return new Error(error); }));
            promises.push(_this.indexedDBService.database.getAllFast(_this.indexedDBService.tableReportsBuildingsPush)
                .then(function (report_buildings) {
                var filtered = [];
                report_buildings.forEach(function (report_building) {
                    if (report_building.report_id === report_id) {
                        filtered.push(report_building.entryId);
                    }
                });
                if (filtered.length) {
                    return _this.indexedDBService.database.deleteBulk(_this.indexedDBService.tableReportsBuildingsPush, filtered)
                        .then(function () { return true; })
                        .catch(function (error) { return new Error(error); });
                }
                return true;
            })
                .catch(function (error) { return new Error(error); }));
            return Promise.all(promises).then(function () { return resolve(); });
        });
    };
    ReportsService.prototype.deleteReportBuildingsReports = function (report_id, building_id) {
        var _this = this;
        if (building_id === void 0) { building_id = null; }
        return new Promise(function (resolve) {
            var promises = [];
            promises.push(_this.indexedDBService.database.getAllFast(_this.indexedDBService.tableReportsBuildingsReports)
                .then(function (reports_buildings_reports) {
                var filtered = [];
                reports_buildings_reports.forEach(function (report_building_report) {
                    if (building_id === null && report_building_report.report_id === report_id || building_id !== null && report_building_report.id === building_id && report_building_report.report_id === report_id) {
                        filtered.push(report_building_report.id);
                    }
                });
                if (filtered.length) {
                    return _this.indexedDBService.database.deleteBulk(_this.indexedDBService.tableReportsBuildingsReports, filtered)
                        .then(function () { return true; })
                        .catch(function (error) { return new Error(error); });
                }
            })
                .catch(function (error) { return new Error(error); }));
            promises.push(_this.indexedDBService.database.getAllFast(_this.indexedDBService.tableReportsBuildingsReportsPush)
                .then(function (reports_buildings_reports) {
                var filtered = [];
                reports_buildings_reports.forEach(function (report_building_report) {
                    if (building_id === null && report_building_report.report_id === report_id || building_id !== null && report_building_report.id === building_id && report_building_report.report_id === report_id) {
                        filtered.push(report_building_report.id);
                    }
                });
                if (filtered.length) {
                    return _this.indexedDBService.database.deleteBulk(_this.indexedDBService.tableReportsBuildingsReportsPush, filtered)
                        .then(function () { return true; })
                        .catch(function (error) { return new Error(error); });
                }
            })
                .catch(function (error) { return new Error(error); }));
            promises.push(_this.indexedDBService.database.getAllFast(_this.indexedDBService.tableReportsBuildingsReportsPhotos)
                .then(function (reports_buildings_reports_photos) {
                var filtered = [];
                reports_buildings_reports_photos.forEach(function (report_building_report_photo) {
                    if (building_id === null && report_building_report_photo.report_id === report_id || building_id !== null && report_building_report_photo.id === building_id && report_building_report_photo.report_id === report_id) {
                        filtered.push(report_building_report_photo.id);
                    }
                });
                if (filtered.length) {
                    return _this.indexedDBService.database.deleteBulk(_this.indexedDBService.tableReportsBuildingsReportsPhotos, filtered)
                        .then(function () { return true; })
                        .catch(function (error) { return new Error(error); });
                }
                return true;
            })
                .catch(function (error) { return new Error(error); }));
            return Promise.all(promises).then(function () { return resolve(); });
        });
    };
    ReportsService.prototype.deleteReportComments = function (report_id) {
        var _this = this;
        return new Promise(function (resolve) {
            var promises = [];
            promises.push(_this.indexedDBService.database.getAllFast(_this.indexedDBService.tableReportsComments)
                .then(function (reports_comments) {
                var filtered = [];
                reports_comments.forEach(function (report_comment) {
                    if (report_comment.report_id === report_id) {
                        filtered.push(report_comment.id);
                    }
                });
                if (filtered.length) {
                    return _this.indexedDBService.database.deleteBulk(_this.indexedDBService.tableReportsComments, filtered)
                        .then(function () { return true; })
                        .catch(function (error) { return new Error(error); });
                }
                return true;
            })
                .catch(function (error) { return new Error(error); }));
            promises.push(_this.indexedDBService.database.getAllFast(_this.indexedDBService.tableReportsCommentsPush)
                .then(function (reports_comments) {
                var filtered = [];
                reports_comments.forEach(function (report_comment) {
                    if (report_comment.report_id === report_id) {
                        filtered.push(report_comment.entryId);
                    }
                });
                if (filtered.length) {
                    return _this.indexedDBService.database.deleteBulk(_this.indexedDBService.tableReportsCommentsPush, filtered)
                        .then(function () { return true; })
                        .catch(function (error) { return new Error(error); });
                }
                return true;
            })
                .catch(function (error) { return new Error(error); }));
            return Promise.all(promises).then(function () { return resolve(); });
        });
    };
    return ReportsService;
}());
export { ReportsService };
