<img src="/assets/images/logo.png" alt="KVVM" width="250" height="83">
<h2>{{ 'general.app-name' | translate }}</h2>
<form [formGroup]="getTokenForm" (ngSubmit)="authenticationService.lostPasswordCreateToken(getTokenForm.value)">
	<mat-form-field>
		<input title="" matInput placeholder="{{ 'shared.email' | translate }}" type="email" name="email" required formControlName="email" autocomplete="email">
		<mat-error *ngIf="formsService.getFieldError('email')">{{formsService.getFieldError('email')}}</mat-error>
	</mat-form-field>
	<button color="primary" mat-raised-button [disabled]="!getTokenForm.valid">{{ 'auth.forgot-password.action' | translate}}</button>
</form>
<a [routerLink]="['/login']">{{ 'shared.actions.back-to-login' | translate }}</a>
