<ng-container *ngIf="!status">
	<strong>{{ 'security.two-factor-auth-enable.activate-two-factor-authentication' | translate }}</strong>
	<ol>
		<li [innerHTML]="'security.two-factor-auth-enable.start-your-authentication-app' | translate"></li>
		<li>
			{{ 'security.two-factor-auth-enable.paste-the-security-code' | translate }}
			<mat-form-field>
				<input matInput placeholder="Secret" type="text" readonly value="{{ otpSecret }}">
				<button matSuffix mat-icon-button color="primary" (click)="copySecret()" matTooltip="Tap to copy">
					<mat-icon>file_copy</mat-icon>
				</button>
			</mat-form-field>
			<button color="primary" mat-button (click)="copySecret()">{{ 'security.two-factor-auth-enable.copy-code' | translate }}</button>
		</li>
		<li>
			<p>{{ 'security.two-factor-auth-enable.paste-the-auth-code' | translate }}</p>
			<form [formGroup]="otpForm" (ngSubmit)="authenticationService.enableTwoFactorAuth(otpForm.value)">
				<mat-form-field>
					<input matInput placeholder="{{ 'shared.code' | translate }}" type="number" required formControlName="code">
					<mat-error *ngIf="formService.getFieldError('code')">{{ formService.getFieldError('code') }}</mat-error>
				</mat-form-field>
				<button color="primary" mat-button [disabled]="!otpForm.valid">{{ 'shared.actions.confirm' | translate }}</button>
			</form>
		</li>
	</ol>
</ng-container>
<ng-container *ngIf="status">
	<div class="alert alert-info" fxFlex fxLayoutAlign="center" fxLayout="row">
		<mat-icon>warning</mat-icon>
		<h2>{{ 'errors.two-factor-auth-is-already-enabled' | translate }}</h2>
	</div>
</ng-container>
