<button mat-button color="primary-outline" [routerLink]="['/reports/' + report_id + '/view']">View inspection</button>
<ng-container *ngIf="dataLoaded && !missingData.length">
	<form class="with-action-buttons" [formGroup]="reportsEditForm" (ngSubmit)="reportsService.updateReport(report_id, reportsEditForm.value)">
		<div class="divider"><span>Inspection details</span></div>
		<input type="hidden" formControlName="type">
		<ng-container *ngIf="reportsEditForm.get('type').value == 'warehouse'">
			<mat-form-field>
				<input matInput title="" type="text" formControlName="report_nr" required placeholder="Inspection name">
				<mat-error *ngIf="formsService.getFieldError('report_nr')">{{formsService.getFieldError('report_nr')}}</mat-error>
			</mat-form-field>
			<mat-form-field>
				<textarea matInput title="" formControlName="client_description" rows="8" placeholder="Description"></textarea>
				<mat-error *ngIf="formsService.getFieldError('client_description')">{{formsService.getFieldError('client_description')}}</mat-error>
			</mat-form-field>
		</ng-container>
		<ng-container *ngIf="reportsEditForm.get('type').value != 'warehouse'">
			<mat-form-field>
				<input matInput readonly value="{{year}}" placeholder="Year">
			</mat-form-field>
			<mat-form-field>
				<input matInput title="" type="number" formControlName="week" placeholder="Week #">
				<mat-error *ngIf="formsService.getFieldError('week')">{{formsService.getFieldError('week')}}</mat-error>
			</mat-form-field>
		</ng-container>
		<div class="action-buttons">
			<button color="primary" mat-button [disabled]="!reportsEditForm.valid">SAVE</button>
		</div>
	</form>
</ng-container>
<div *ngIf="missingData.length" class="alert alert-danger" fxFlex fxLayout="row">
	<mat-icon>warning</mat-icon>
	<h2>Not all data is available online, go online to continue.</h2>
</div>
