<ng-container *ngIf="status">
	<div *ngIf="tfa_required" class="alert alert-danger" fxFlex fxLayoutAlign="center" fxLayout="row">
		<mat-icon>warning</mat-icon>
		<h2>{{ 'security.two-factor-auth-disable.tfa-is-required' | translate }}</h2>
	</div>
	<p>{{ 'security.two-factor-auth-disable.enter-tfa-code' | translate }}</p>
	<form [formGroup]="otpForm" (ngSubmit)="authenticationService.disableTwoFactorAuth(otpForm.value)">
		<mat-form-field>
			<input matInput placeholder="{{ 'shared.code' | translate }}" type="number" required formControlName="code" autofocus>
			<mat-error *ngIf="formService.getFieldError('code')">{{ formService.getFieldError('code') }}</mat-error>
		</mat-form-field>
		<button color="primary" mat-button [disabled]="!otpForm.valid">{{ 'shared.actions.confirm' | translate }}</button>
	</form>
</ng-container>
<ng-container *ngIf="!status">
	<div class="alert alert-info" fxFlex fxLayoutAlign="center" fxLayout="row">
		<mat-icon>warning</mat-icon>
		<h2>{{ 'errors.two-factor-auth-is-not-enabled' | translate }}</h2>
	</div>
</ng-container>
