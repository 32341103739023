<img src="/assets/images/logo.png" alt="KVVM" width="250" height="83">
<h2>{{ 'general.app-name' | translate }}</h2>
<form [formGroup]="createPasswordForm" (ngSubmit)="authenticationService.recoverLostPassword(createPasswordForm.value)">
	<input type="hidden" name="token">
	<mat-form-field>
		<input title="" matInput placeholder="{{ 'auth.recover-lost-password.new-password' | translate }}" type="password" name="new_password" required formControlName="new_password" autocomplete="new-password">
		<mat-error *ngIf="formsService.getFieldError('email')">{{formsService.getFieldError('email')}}</mat-error>
	</mat-form-field>
	<mat-form-field>
		<input title="" matInput placeholder="{{ 'auth.recover-lost-password.confirm-new-password' | translate }}" type="password" name="repeat_new_password" required formControlName="repeat_new_password" autocomplete="repeat-new-password">
		<mat-error *ngIf="formsService.getFieldError('email')">{{formsService.getFieldError('email')}}</mat-error>
	</mat-form-field>
	<button color="primary" mat-raised-button [disabled]="!createPasswordForm.valid">{{ 'auth.recover-lost-password.action' | translate}}</button>
</form>
<a [routerLink]="['/login']">{{ 'shared.actions.back-to-login' | translate }}</a>
