<app-ngbox></app-ngbox>
<app-toolbar [sidenav]="sidenav"></app-toolbar>
<mat-sidenav-container class="admin-container" (backdropClick)="sidenav.close()">
	<mat-sidenav class="sidenav" opened="false" position="start" mode="over" #sidenav>
		<app-sidenav></app-sidenav>
	</mat-sidenav>
	<div class="content-container">
		<div class="loading-overlay" [class.show]="isLoading">
			<mat-progress-bar [mode]="progress_type" [value]="progress_percentage"></mat-progress-bar>
		</div>
		<div *ngIf="loadingError" class="alert alert-danger" fxFlex fxLayoutAlign="center" fxLayout="row">
			<h2>{{ 'errors.loading-data' | translate }}</h2>
		</div>
		<div *ngIf="offlineError" class="alert alert-danger" fxFlex fxLayoutAlign="center" fxLayout="row">
			<h2>{{ 'errors.unavailable-offline' | translate }}</h2>
		</div>
		<div *ngIf="tfa_required" class="alert alert-danger" fxFlex fxLayoutAlign="center" fxLayout="row">
			<h2>{{ 'errors.two-factor-auth-required' | translate }}</h2>
		</div>
		<div *ngIf="tfa_not_enabled" class="alert alert-danger" fxFlex fxLayoutAlign="center" fxLayout="row">
			<h2>{{ 'errors.two-factor-auth-disabled' | translate }}</h2>
		</div>
		<router-outlet></router-outlet>
	</div>
</mat-sidenav-container>
<app-toolbar-bottom></app-toolbar-bottom>
