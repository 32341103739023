<button mat-button color="primary-outline" [routerLink]="['/kvvm-reports/' + report_id + '/buildings/' + building_id + '/reports']">View building / area inspections</button>
<button mat-button color="primary-outline" [routerLink]="['/kvvm-reports/' + report_id + '/buildings']">View all buildings / areas</button>
<button mat-button color="primary-outline" [routerLink]="['/kvvm-reports/' + report_id + '/view']">View inspection</button>
<ng-container *ngIf="buildingReport">
	<form class="with-action-buttons" [formGroup]="reportsBuildingsReportUpdateForm" (ngSubmit)="reportsService.updateBuildingReport(report_id, building_id, building_report_id, reportsBuildingsReportUpdateForm.value)">
		<div class="divider"><span>Building /area inspection details</span></div>
		<div class="form-line" *ngIf="buildingReport.type === 'warehouse'">
			<div class="form-label">Location number</div>
			<div class="form-content">{{ buildingReport.location_nr }}</div>
		</div>
		<div class="form-line" *ngIf="buildingReport.type === 'ladders'">
			<div class="form-label">Number</div>
			<div class="form-content">{{ buildingReport.location_nr }}</div>
		</div>
		<div class="form-line" *ngIf="buildingReport.type === 'ladders'">
			<div class="form-label">ID</div>
			<div class="form-content">{{ buildingReport.ladder_id }}</div>
		</div>
		<div class="form-line">
			<div class="form-label">Category</div>
			<div class="form-content">{{ buildingReport.category_name }}</div>
		</div>
		<div class="form-line">
			<div class="form-label">Sub category</div>
			<div class="form-content">{{ buildingReport.subcategory_name }}</div>
		</div>
		<div class="form-line">
			<div class="form-label">Color code</div>
			<div class="form-content">
				<div class="color" [style.background]="buildingReport.color"></div>
				<div class="text">{{ buildingReport.color_text }}</div>
			</div>
		</div>
		<div class="form-line" *ngIf="buildingReport.type === 'warehouse'">
			<div class="form-label">Depth</div>
			<div class="form-content">{{ buildingReport.measure_depth }}</div>
		</div>
		<div class="form-line" *ngIf="buildingReport.type === 'warehouse'">
			<div class="form-label">Height</div>
			<div class="form-content">{{ buildingReport.measure_height }}</div>
		</div>
		<div class="form-line" *ngIf="buildingReport.type === 'ladders'">
			<div class="form-label">Material</div>
			<div class="form-content">{{ buildingReport.material_name }}</div>
		</div>
		<div class="form-line" *ngIf="buildingReport.type === 'ladders'">
			<div class="form-label">Brand</div>
			<div class="form-content">{{ buildingReport.brand }}</div>
		</div>
		<div class="form-line" *ngIf="buildingReport.type === 'ladders'">
			<div class="form-label">Inspection sticker placed</div>
			<div class="form-content">{{ buildingReport.sticker_posted }}</div>
		</div>
		<div class="form-line">
			<div class="form-label">Explanation</div>
			<div class="form-content" *ngIf="buildingReport.description">{{ buildingReport.description }}</div>
			<div class="form-content" *ngIf="!buildingReport.description">-</div>
		</div>
		<ng-container *ngIf="buildingReport.type === 'warehouse'">
			<ng-container *ngIf="buildingReport.warehouse_types.length">
				<div class="divider"><span>Shortcoming types</span></div>
				<ul>
					<li *ngFor="let type of buildingReport.warehouse_types">
						{{ type.type }} <span *ngIf="type.result">{{ type.result }}</span>
					</li>
				</ul>
			</ng-container>
			<ng-container *ngIf="buildingReport.warehouse_actions.length">
				<div class="divider"><span>Actions</span></div>
				<ul>
					<li *ngFor="let action of buildingReport.warehouse_actions">
						{{ action.action }}
					</li>
				</ul>
			</ng-container>
		</ng-container>
		<ng-container *ngIf="buildingReport.type === 'ladders'">
			<ng-container *ngIf="buildingReport.questionnaire.length">
				<div class="questionnaire-container view-only" *ngFor="let questionnaire of buildingReport.questionnaire">
					<div class="questionnaire-header">
						<h4>{{ questionnaire.name }}</h4>
						<p *ngIf="questionnaire.description">{{ questionnaire.description }}</p>
					</div>
					<div class="questionnaire-body">
						<ng-container *ngIf="questionnaire.questions.length">
							<div class="questionnaire-question" *ngFor="let question of questionnaire.questions">
								<span class="questionnaire-color-answer" [style.background]="question.color"></span>
								<p>{{ question.question }}</p>
							</div>
						</ng-container>
					</div>
				</div>
			</ng-container>
		</ng-container>
		<ng-container *ngIf="buildingReport?.photos?.inspector">
			<div class="divider"><span>Uploaded photos</span></div>
			<div class="image-container">
				<div *ngFor="let photo of buildingReport.photos.inspector; let i = index">
					<img src="{{ photo.filename_url }}" alt="">
				</div>
			</div>
		</ng-container>
		<ng-container *ngIf="buildingReport?.photos?.by_client">
			<div class="divider"><span>Client's uploaded photos</span></div>
			<div class="image-container">
				<div *ngFor="let photo of buildingReport.photos.by_client; let i = index">
					<img src="{{ photo.filename_url }}" alt="">
				</div>
			</div>
		</ng-container>
		<ng-container *ngIf="hasBuildingMap && apiService.isOnline">
			<div class="divider"><span>Building Map</span></div>
			<button type="button" mat-button color="primary-outline" (click)="showBuildingMap()">View deviation on map</button>
		</ng-container>
		<ng-container *ngIf="buildingReport.color_code !== 2 && buildingReport.color_code !== '2'">
			<div class="divider"><span>Status</span></div>
			<ng-container *ngIf="buildingReport.is_resolved !== true">
				<mat-checkbox color="primary" formControlName="client_fixed_status" value="1" (change)="fixAngularScrewUpAndInsertActualValue2('client_fixed_status', $event)">Was this damage resolved?</mat-checkbox>
				<mat-form-field>
					<textarea title="" matInput rows="6" placeholder="Please explain how this damage has been resolved" id="client_fixed_comment" formControlName="client_fixed_comment"></textarea>
				</mat-form-field>
				<div class="action-buttons">
					<button color="primary" mat-button [disabled]="!reportsBuildingsReportUpdateForm.valid">SAVE</button>
				</div>
			</ng-container>
			<ng-container *ngIf="buildingReport.is_resolved === true">
				<div class="form-line">
					<div class="form-label">Was this damage resolved?</div>
					<div class="form-content">Yes</div>
				</div>
				<div class="form-line">
					<div class="form-label">Date</div>
					<div class="form-content" *ngIf="buildingReport.client_fixed_date">{{ buildingReport.client_fixed_date | datex }}</div>
					<div class="form-content" *ngIf="!buildingReport.client_fixed_date">-</div>
				</div>
				<div class="form-line">
					<div class="form-label">Details</div>
					<div class="form-content" *ngIf="buildingReport.client_fixed_comment">{{ buildingReport.client_fixed_comment }}</div>
					<div class="form-content" *ngIf="!buildingReport.client_fixed_comment">-</div>
				</div>
			</ng-container>
		</ng-container>
	</form>
</ng-container>
<div *ngIf="!buildingReport" class="alert alert-danger" fxFlex fxLayout="row">
	<mat-icon>warning</mat-icon>
	<h2>Not all data is available online, go online to continue.</h2>
</div>
