<dl *ngIf="report">
	<dt>Week #</dt>
	<dd>{{ report.report_nr }}</dd>
	<dt>Date created</dt>
	<dd *ngIf="report.created !== null">{{ report.created | datex:'DD-MM-YYYY' }}</dd>
	<dd *ngIf="report.created === null">-</dd>
	<dt>Date updated</dt>
	<dd *ngIf="report.modified !== null">{{ report.modified | datex:'DD-MM-YYYY' }}</dd>
	<dd *ngIf="report.modified === null">-</dd>
</dl>
<div class="divider"><span>Checked hallways</span></div>
<div class="hallways">
	<div *ngFor="let i of hallways" class="hallway" [class.selected]="reportHallways.indexOf(i) !== -1">{{ i}}</div>
</div>
<br>
<app-reports-pallet-report-list [reportId]="report.id" [palletOptions]="palletOptions" (reportHallways)="addHallways($event)"></app-reports-pallet-report-list>
<div class="divider"><span>Download</span></div>
<div class="buttons-in-line" fxLayout="row">
	<button *ngIf="apiService.isOnline" mat-button color="primary-outline" (click)="downloadPDFReport(report.id, 'pdf')">PDF</button>
	<button *ngIf="!apiService.isOnline" mat-button color="grey-outline" (click)="apiService.notAvailableOffline()">PDF</button>
</div>
