/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./reports-view-pallet-report.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i4 from "@angular/material/button";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "../../../../_pipes/pipe.datex";
import * as i8 from "../../reports-pallet-reports/reports-pallet-reports-list/reports-pallet-reports-list.component.ngfactory";
import * as i9 from "../../reports-pallet-reports/reports-pallet-reports-list/reports-pallet-reports-list.component";
import * as i10 from "../../../../_services/reports.service";
import * as i11 from "@angular/flex-layout/flex";
import * as i12 from "@angular/flex-layout/core";
import * as i13 from "./reports-view-pallet-report.component";
import * as i14 from "../../../../_interceptors/api.service";
import * as i15 from "../../../../_services/snackbar.service";
var styles_ReportsViewPalletReportComponent = [i0.styles];
var RenderType_ReportsViewPalletReportComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ReportsViewPalletReportComponent, data: { "animation": [{ type: 7, name: "routeAnimation", definitions: [{ type: 1, expr: "void => *", animation: [{ type: 6, styles: { opacity: 0 }, offset: null }, { type: 4, styles: { type: 6, styles: { opacity: 1 }, offset: null }, timings: "400ms 150ms ease-in-out" }], options: null }], options: {} }] } });
export { RenderType_ReportsViewPalletReportComponent as RenderType_ReportsViewPalletReportComponent };
function View_ReportsViewPalletReportComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "dd", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵppd(2, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent, 0), _co.report.created, "DD-MM-YYYY")); _ck(_v, 1, 0, currVal_0); }); }
function View_ReportsViewPalletReportComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "dd", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["-"]))], null, null); }
function View_ReportsViewPalletReportComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "dd", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵppd(2, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent, 0), _co.report.modified, "DD-MM-YYYY")); _ck(_v, 1, 0, currVal_0); }); }
function View_ReportsViewPalletReportComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "dd", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["-"]))], null, null); }
function View_ReportsViewPalletReportComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, "dl", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "dt", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Week #"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "dd", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "dt", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Date created"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReportsViewPalletReportComponent_2)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReportsViewPalletReportComponent_3)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(11, 0, null, null, 1, "dt", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Date updated"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReportsViewPalletReportComponent_4)), i1.ɵdid(14, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReportsViewPalletReportComponent_5)), i1.ɵdid(16, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.report.created !== null); _ck(_v, 8, 0, currVal_1); var currVal_2 = (_co.report.created === null); _ck(_v, 10, 0, currVal_2); var currVal_3 = (_co.report.modified !== null); _ck(_v, 14, 0, currVal_3); var currVal_4 = (_co.report.modified === null); _ck(_v, 16, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.report.report_nr; _ck(_v, 4, 0, currVal_0); }); }
function View_ReportsViewPalletReportComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "hallway"]], [[2, "selected", null]], null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.reportHallways.indexOf(_v.context.$implicit) !== (0 - 1)); _ck(_v, 0, 0, currVal_0); var currVal_1 = _v.context.$implicit; _ck(_v, 1, 0, currVal_1); }); }
function View_ReportsViewPalletReportComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["color", "primary-outline"], ["mat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.downloadPDFReport(_co.report.id, "pdf") !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, ["PDF"]))], function (_ck, _v) { var currVal_2 = "primary-outline"; _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_ReportsViewPalletReportComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["color", "grey-outline"], ["mat-button", ""]], [[1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.apiService.notAvailableOffline() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_MatButton_0, i3.RenderType_MatButton)), i1.ɵdid(1, 180224, null, 0, i4.MatButton, [i1.ElementRef, i5.FocusMonitor, [2, i6.ANIMATION_MODULE_TYPE]], { color: [0, "color"] }, null), (_l()(), i1.ɵted(-1, 0, ["PDF"]))], function (_ck, _v) { var currVal_2 = "grey-outline"; _ck(_v, 1, 0, currVal_2); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 1).disabled || null); var currVal_1 = (i1.ɵnov(_v, 1)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_ReportsViewPalletReportComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i7.DatexPipe, []), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReportsViewPalletReportComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "divider"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Checked hallways"])), (_l()(), i1.ɵeld(6, 0, null, null, 2, "div", [["class", "hallways"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReportsViewPalletReportComponent_6)), i1.ɵdid(8, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "app-reports-pallet-report-list", [], [[40, "@routeAnimation", 0]], [[null, "reportHallways"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("reportHallways" === en)) {
        var pd_0 = (_co.addHallways($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_ReportsPalletReportsListComponent_0, i8.RenderType_ReportsPalletReportsListComponent)), i1.ɵdid(11, 114688, null, 0, i9.ReportsPalletReportsListComponent, [i10.ReportsService], { reportId: [0, "reportId"], palletOptions: [1, "palletOptions"] }, { reportHallways: "reportHallways" }), (_l()(), i1.ɵeld(12, 0, null, null, 2, "div", [["class", "divider"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Download"])), (_l()(), i1.ɵeld(15, 0, null, null, 5, "div", [["class", "buttons-in-line"], ["fxLayout", "row"]], null, null, null, null, null)), i1.ɵdid(16, 671744, null, 0, i11.DefaultLayoutDirective, [i1.ElementRef, i12.StyleUtils, [2, i11.LayoutStyleBuilder], i12.MediaMarshaller], { fxLayout: [0, "fxLayout"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReportsViewPalletReportComponent_7)), i1.ɵdid(18, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReportsViewPalletReportComponent_8)), i1.ɵdid(20, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.report; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.hallways; _ck(_v, 8, 0, currVal_1); var currVal_3 = _co.report.id; var currVal_4 = _co.palletOptions; _ck(_v, 11, 0, currVal_3, currVal_4); var currVal_5 = "row"; _ck(_v, 16, 0, currVal_5); var currVal_6 = _co.apiService.isOnline; _ck(_v, 18, 0, currVal_6); var currVal_7 = !_co.apiService.isOnline; _ck(_v, 20, 0, currVal_7); }, function (_ck, _v) { var currVal_2 = i1.ɵnov(_v, 11).routerTransition; _ck(_v, 10, 0, currVal_2); }); }
export function View_ReportsViewPalletReportComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-reports-view-pallet-report", [], [[40, "@routeAnimation", 0]], null, null, View_ReportsViewPalletReportComponent_0, RenderType_ReportsViewPalletReportComponent)), i1.ɵdid(1, 49152, null, 0, i13.ReportsViewPalletReportComponent, [i14.ApiService, i10.ReportsService, i15.SnackbarService], null, null)], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).routerTransition; _ck(_v, 0, 0, currVal_0); }); }
var ReportsViewPalletReportComponentNgFactory = i1.ɵccf("app-reports-view-pallet-report", i13.ReportsViewPalletReportComponent, View_ReportsViewPalletReportComponent_Host_0, { report: "report", palletOptions: "palletOptions" }, {}, []);
export { ReportsViewPalletReportComponentNgFactory as ReportsViewPalletReportComponentNgFactory };
