<button mat-button color="primary-outline" [routerLink]="['/reports/' + report_id + '/view']">View inspection</button>
<ng-container *ngIf="dataLoaded && !missingData.length">
	<form class="with-action-buttons" [formGroup]="reportCommentEditForm" (ngSubmit)="reportsService.updateComment(report_id, comment_id, reportCommentEditForm.value)">
		<mat-form-field>
			<textarea title="" matInput placeholder="Comment" required formControlName="comment" rows="8"></textarea>
			<mat-error *ngIf="formsService.getFieldError('comment')">{{formsService.getFieldError('comment')}}</mat-error>
		</mat-form-field>
		<div class="action-buttons">
			<button color="primary" mat-button [disabled]="!reportCommentEditForm.valid">SAVE</button>
		</div>
	</form>
</ng-container>
<div *ngIf="missingData.length" class="alert alert-danger" fxFlex fxLayout="row">
	<mat-icon>warning</mat-icon>
	<h2>Not all data is available online, go online to continue.</h2>
</div>
