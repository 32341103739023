<button mat-button color="primary-outline" [routerLink]="['/reports/' + report_id + '/view']">View inspection</button>
<ng-container *ngIf="dataLoaded && !missingData.length">
	<dl *ngIf="report">
		<dt>Inspection number</dt>
		<dd>{{ report.report_nr }}</dd>
		<dt>Inspector</dt>
		<dd>{{ report.inspector }}</dd>
		<dt>Date created</dt>
		<dd *ngIf="report.created !== null">{{ report.created | datex:'DD-MM-YYYY' }}</dd>
		<dd *ngIf="report.created === null">-</dd>
		<dt>Date edited</dt>
		<dd *ngIf="report.modified !== null">{{ report.modified | datex:'DD-MM-YYYY' }}</dd>
		<dd *ngIf="report.modified === null">-</dd>
	</dl>
	<form class="with-action-buttons" [formGroup]="reportSendToClientForm" (ngSubmit)="reportsService.sendToEmail(report_id, reportSendToClientForm.value)">
		<div class="divider"><span>Send to custom recipients</span></div>
		<mat-form-field>
			<textarea title="" matInput placeholder="Custom recipients" formControlName="custom_contacts" rows="8"></textarea>
			<mat-error *ngIf="formsService.getFieldError('custom_contacts')">{{formsService.getFieldError('custom_contacts')}}</mat-error>
		</mat-form-field>
		<div class="divider"><span>Attachments</span></div>
		<div>
			<mat-radio-group color="primary" formControlName="report_format">
				<mat-radio-button value="pdf">PDF report</mat-radio-button>
				<mat-radio-button value="xlsx">Excel report</mat-radio-button>
				<mat-radio-button value="both">PDF & Excel report</mat-radio-button>
			</mat-radio-group>
		</div>
		<div class="divider"><span>Message details</span></div>
		<mat-form-field>
			<textarea title="" matInput placeholder="Notes" formControlName="message" rows="8"></textarea>
			<mat-error *ngIf="formsService.getFieldError('message')">{{formsService.getFieldError('message')}}</mat-error>
		</mat-form-field>
		<div class="action-buttons">
			<button color="primary" mat-button [disabled]="!reportSendToClientForm.valid">SEND</button>
		</div>
	</form>
</ng-container>
<div *ngIf="missingData.length" class="alert alert-danger" fxFlex fxLayout="row">
	<mat-icon>warning</mat-icon>
	<h2>Not all data is available online, go online to continue.</h2>
</div>
