<button mat-button color="primary" [routerLink]="['/reports/' + report_id + '/buildings/' + building_id + '/reports/create']">Start inspection</button>
<ng-container *ngIf="smallWidget === false">
	<button mat-button color="primary-outline" [routerLink]="['/reports/' + report_id + '/buildings']">View all buildings / areas</button>
	<button mat-button color="primary-outline" [routerLink]="['/reports/' + report_id + '/view']">View inspection</button>
	<app-search-element (formSubmittedEvent)="search($event)"></app-search-element>
</ng-container>
<mat-nav-list class="mat-nav-list-with-border" infiniteScroll infiniteScrollDistance="1" (scrolled)="getReportsBuildingReports()">
	<mat-list-item *ngFor="let buildingReport of buildingReports">
		<div class="mat-list-content" [routerLink]="['/reports/' + report_id + '/buildings/' + building_id + '/reports/' + buildingReport.id + '/edit']">
			<h4 matLine>{{ buildingReport.location_nr }}</h4>
			<a matLine>{{ buildingReport.category_name }}; {{ buildingReport.subcategory_name }}</a>
		</div>
		<ng-container *ngIf="buildingReport.color">
			<div class="tag" [style.background]="buildingReport.color"></div>
		</ng-container>
		<ng-container *ngIf="buildingReport.is_resolved">
			<mat-icon class="is_resolved">check_circle_outline</mat-icon>
		</ng-container>
		<button mat-icon-button color="white-no-border" [matMenuTriggerFor]="menu">
			<mat-icon>more_vert</mat-icon>
		</button>
		<mat-menu #menu="matMenu">
			<a mat-menu-item [routerLink]="['/reports/' + report_id + '/buildings/' + building_id + '/reports/' + buildingReport.id + '/edit']">Edit</a>
			<a mat-menu-item (click)="deleteBuildingReport(buildingReport.id)">Delete</a>
		</mat-menu>
	</mat-list-item>
</mat-nav-list>
