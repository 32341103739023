<button mat-button color="primary-outline" [routerLink]="['/reports']">View all inspections</button>
<ng-container *ngIf="dataLoaded && !missingData.length">
	<button class="inline-edit-button" mat-icon-button [routerLink]="['/reports/' + report.id + '/edit']">
		<mat-icon>edit</mat-icon>
	</button>
	<app-reports-view-warehouse *ngIf="report.type == 'warehouse'" [report]="report"></app-reports-view-warehouse>
	<app-reports-view-compliance *ngIf="report.type == 'compliance'" [report]="report"></app-reports-view-compliance>
	<app-reports-view-pallet-report *ngIf="report.type == 'ikea-pallet'" [report]="report" [palletOptions]="palletOptions"></app-reports-view-pallet-report>
</ng-container>
<div *ngIf="missingData.length" class="alert alert-danger" fxFlex fxLayout="row">
	<mat-icon>warning</mat-icon>
	<h2>Not all data is available online, go online to continue.</h2>
</div>
