<button mat-button color="primary-outline" [routerLink]="['/reports/' + report_id + '/view']">View inspection</button>
<form [formGroup]="complianceForm" (ngSubmit)="submitCompliance(complianceForm.value)">
	<mat-form-field>
		<input title="" matInput placeholder="From row" type="text" formControlName="from" required>
		<mat-error *ngIf="formsService.getFieldError('from')">{{formsService.getFieldError('from')}}</mat-error>
	</mat-form-field>
	<mat-form-field>
		<input title="" matInput placeholder="Till row" type="text" formControlName="till" required>
		<mat-error *ngIf="formsService.getFieldError('till')">{{formsService.getFieldError('till')}}</mat-error>
	</mat-form-field>
	<mat-form-field>
		<textarea title="" rows="2" matInput placeholder="Includes" formControlName="includes"></textarea>
		<mat-error *ngIf="formsService.getFieldError('includes')">{{formsService.getFieldError('includes')}}</mat-error>
	</mat-form-field>
	<mat-form-field>
		<textarea title="" rows="4" matInput placeholder="notes" formControlName="notes"></textarea>
		<mat-error *ngIf="formsService.getFieldError('notes')">{{formsService.getFieldError('notes')}}</mat-error>
	</mat-form-field>
	<div>
		<button color="primary" mat-button [disabled]="!complianceForm.valid">
			<ng-container *ngIf="compliance_id">UPDATE</ng-container>
			<ng-container *ngIf="!compliance_id">ADD</ng-container>
		</button>
	</div>
</form>
<mat-nav-list *ngIf="reportCompliances.length" class="mat-nav-list-with-border">
	<mat-list-item *ngFor="let compliance of reportCompliances">
		<div class="mat-list-content compliance" [routerLink]="'/reports/' + report_id + '/compliances/' + weekday + '/' + compliance.id">
			<p matLine><span>From row:</span> {{compliance.from}}</p>
			<p matLine><span>Till row:</span> {{compliance.till}}</p>
			<p matLine><span>Includes:</span> {{compliance.includes}}</p>
			<p matLine><span>Notes:</span> {{compliance.notes}}</p>
		</div>
		<button mat-icon-button color="white-no-border" [matMenuTriggerFor]="menu">
			<mat-icon>more_vert</mat-icon>
		</button>
		<mat-menu #menu="matMenu">
			<a mat-menu-item [routerLink]="'/reports/' + report_id + '/compliances/' + weekday + '/' + compliance.id">Edit</a>
			<a mat-menu-item (click)="deleteCompliance(compliance.report_id, compliance.weekday, compliance.id)">Delete</a>
		</mat-menu>
	</mat-list-item>
</mat-nav-list>
<div *ngIf="reportCompliances.length === 0">
	<br>No compliance checklists found.
</div>
