<ng-container *ngIf="smallWidget === false">
	<button mat-button color="primary-outline" [routerLink]="['/kvvm-reports/' + report_id + '/view']">View inspection</button>
	<app-search-element (formSubmittedEvent)="search($event)"></app-search-element>
</ng-container>
<mat-nav-list *ngIf="reportComments.length" class="mat-nav-list-with-border">
	<div *ngIf="!smallWidget" infiniteScroll infiniteScrollDistance="1" (scrolled)="getComments()"></div>
	<mat-list-item *ngFor="let comment of reportComments" [class.no-bottom-border]="smallWidget === true">
		<div class="mat-list-content">
			<h4 matLine>{{ comment.created | datex:'DD-MM-YYYY' }} - {{ comment.inspector }}</h4>
			<a matLine>{{ comment.comment }}</a>
		</div>
	</mat-list-item>
	<a mat-list-item *ngIf="smallWidget === true && totalItems > 5" [routerLink]="['/kvvm-reports/' + report_id + '/comments']" class="mat-list-item-more">
		<span matLine>More ...</span>
		<button mat-icon-button>
			<mat-icon>chevron_right</mat-icon>
		</button>
	</a>
</mat-nav-list>
<div *ngIf="reportComments.length === 0">
	<br> No comments found.
</div>
