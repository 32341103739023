<mat-nav-list *ngIf="reportCompliances.length" class="mat-nav-list-with-border">
	<mat-list-item *ngFor="let compliance of reportCompliances">
		<div class="mat-list-content" [routerLink]="['/reports/' + report_id + '/compliances/' + compliance.weekday]">
			<h4 matLine>{{ compliance.day }}</h4>
		</div>
		<mat-icon *ngIf="compliance.status" class="is_resolved">check_circle_outline</mat-icon>
		<mat-icon *ngIf="!compliance.status" class="is_not_resolved">check_circle_outline</mat-icon>
	</mat-list-item>
</mat-nav-list>
<div *ngIf="reportCompliances.length === 0">
	<br>No compliance checklists found.
</div>
