<form #search="ngForm" (ngSubmit)="formSubmitted(query)">
	<div fxLayout="row">
		<mat-form-field class="search-input">
			<input title="" matInput placeholder="{{ 'shared.search' | translate }}" name="query" [(ngModel)]="query">
		</mat-form-field>
		<button type="reset" *ngIf="isFiltered" matSuffix mat-icon-button color="primary" class="search-button" (click)="clearSearchInput()">
			<mat-icon>close</mat-icon>
		</button>
		<button type="submit" matSuffix mat-icon-button color="primary" class="search-button">
			<mat-icon>search</mat-icon>
		</button>
	</div>
</form>
