<dl *ngIf="report">
	<dt>Report number</dt>
	<dd>{{ report.report_nr }}</dd>
	<dt>Date created</dt>
	<dd *ngIf="report.created !== null">{{ report.created | datex:'DD-MM-YYYY' }}</dd>
	<dd *ngIf="report.created === null">-</dd>
	<dt>Date updated</dt>
	<dd *ngIf="report.modified !== null">{{ report.modified | datex:'DD-MM-YYYY' }}</dd>
	<dd *ngIf="report.modified === null">-</dd>
	<dt>Color code</dt>
	<dd><div class="tag" [style.background]="report.color"></div></dd>
	<dt>Description</dt>
	<dd>{{report.client_description}}</dd>
</dl>
<div class="divider"><span>Buildings / Areas</span></div>
<app-reports-buildings-list [smallWidget]="true" style="margin:-10px 0 0 0;"></app-reports-buildings-list>
<div class="divider"><span>Download</span></div>
<div class="buttons-in-line" fxLayout="row">
	<button *ngIf="apiService.isOnline" mat-button color="primary-outline" (click)="downloadPDFReport(report.id, 'pdf')">PDF</button>
	<button *ngIf="apiService.isOnline" mat-button color="primary-outline" (click)="downloadXLSReport(report.id, 'xlsx')">XLS</button>
	<button *ngIf="!apiService.isOnline" mat-button color="grey-outline" (click)="apiService.notAvailableOffline()">PDF</button>
	<button *ngIf="!apiService.isOnline" mat-button color="grey-outline" (click)="apiService.notAvailableOffline()">XLS</button>
</div>
<button mat-button color="primary-outline" [routerLink]="['/reports/' + report.id + '/send-to-email']">Send to email</button>
<app-reports-comments-list [smallWidget]="true" style="margin:20px 0 0 0;"></app-reports-comments-list>
<app-reports-attachments-list [smallWidget]="true" style="margin:20px 0 0 0;"></app-reports-attachments-list>
