<button mat-button color="primary-outline" [routerLink]="['/reports/' + report_id + '/view']">View inspection</button>
<br><br>
<form [formGroup]="palletReportForm" (ngSubmit)="reportsService.updatePalletReport(report_id, pallet_report_id, palletReportForm.value)">
	<div class="divider"><span>Checked hallways</span></div>
	<div class="hallways" formArrayName="hallways">
		<span *ngFor="let hallway of hallways; let i = index">
			<mat-checkbox color="primary" formControlName="{{ i }}" value="{{ hallway }}" (change)="fixAngularScrewUpAndInsertActualValue('hallways', $event, i)">{{ hallway }}</mat-checkbox>
		</span>
	</div>
	<div class="checkbox-helper-buttons">
		<button mat-button type="button" color="primary-outline" (click)="checkAll()">SELECT ALL</button>
		<button mat-button type="button" color="primary-outline" (click)="uncheckAll()">DESELECT ALL</button>
	</div>
	<mat-form-field>
		<input title="" matInput placeholder="Racking number" type="text" formControlName="position_nr" required>
		<mat-error *ngIf="formsService.getFieldError('position_nr')">{{formsService.getFieldError('position_nr')}}</mat-error>
	</mat-form-field>
	<mat-form-field>
		<input title="" matInput placeholder="Shelf" type="text" formControlName="field" required>
		<mat-error *ngIf="formsService.getFieldError('field')">{{formsService.getFieldError('field')}}</mat-error>
	</mat-form-field>
	<mat-form-field>
		<input title="" matInput placeholder="Level" type="text" formControlName="level" required>
		<mat-error *ngIf="formsService.getFieldError('level')">{{formsService.getFieldError('level')}}</mat-error>
	</mat-form-field>
	<div class="divider"><span>Deviations</span></div>
	<div *ngFor="let palletOption of palletOptions; let i = index" formArrayName="pallet_option_ids">
		<mat-checkbox color="primary" formControlName="{{ i }}" value="{{ palletOption.id }}" (change)="fixAngularScrewUpAndInsertActualValue('pallet_option_ids', $event, i)">{{ palletOption.option_name }}</mat-checkbox>
	</div>
	<mat-form-field>
		<textarea title="" rows="4" matInput placeholder="Notes" formControlName="notes"></textarea>
		<mat-error *ngIf="formsService.getFieldError('notes')">{{formsService.getFieldError('notes')}}</mat-error>
	</mat-form-field>
	<div>
		<button color="primary" mat-button [disabled]="!palletReportForm.valid">SAVE</button>
	</div>
</form>
