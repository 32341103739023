<button mat-button color="primary-outline" [routerLink]="['/reports']">View all inspections</button>
<form class="with-action-buttons" [formGroup]="reportsCreateForm" (ngSubmit)="reportsService.createReport(reportsCreateForm.value)">
	<div class="divider"><span>Inspection details</span></div>
	<mat-form-field>
		<mat-select title="" placeholder="Report type" required formControlName="type">
			<mat-option value="warehouse">Normal report</mat-option>
			<mat-option value="compliance">Compliance checklist</mat-option>
			<mat-option value="ikea-pallet">Pallet inspection</mat-option>
		</mat-select>
	</mat-form-field>
	<ng-container *ngIf="reportsCreateForm.get('type').value == 'warehouse'">
		<mat-form-field>
			<input matInput title="" type="text" formControlName="report_nr" placeholder="Inspection name">
			<mat-error *ngIf="formsService.getFieldError('report_nr')">{{formsService.getFieldError('report_nr')}}</mat-error>
		</mat-form-field>
		<mat-form-field>
			<textarea matInput title="" formControlName="client_description" rows="8" placeholder="Description"></textarea>
			<mat-error *ngIf="formsService.getFieldError('client_description')">{{formsService.getFieldError('client_description')}}</mat-error>
		</mat-form-field>
	</ng-container>
	<ng-container *ngIf="reportsCreateForm.get('type').value != 'warehouse'">
		<mat-form-field>
			<input matInput readonly value="{{year}}" placeholder="Year">
		</mat-form-field>
		<mat-form-field>
			<input matInput title="" type="number" formControlName="week" placeholder="Week #">
			<mat-error *ngIf="formsService.getFieldError('week')">{{formsService.getFieldError('week')}}</mat-error>
		</mat-form-field>
	</ng-container>
	<div class="action-buttons">
		<button color="primary" mat-button [disabled]="!reportsCreateForm.valid">SAVE</button>
	</div>
</form>
