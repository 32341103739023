<img *ngIf="client_logo" src="{{client_logo}}" alt="Logo" width="250" height="74" crossorigin="anonymous" #image>
<h2 *ngIf="!client_logo">{{ company_name }}</h2>
<button mat-button color="primary" [routerLink]="['/reports/create']">New inspection</button>
<div class="btn-group-rounded">
	<button mat-button color="grey-outline" [routerLink]="['/reports']">Inspections<span class="counter"><span *ngIf="local_report_counting">~</span>{{ reports }}</span></button>
	<button mat-button color="grey-outline" [routerLink]="['/kvvm-reports']">
		KVVM inspections
		<mat-icon style="margin-left: 5px;font-size: 20px;width: 20px;height: 20px;line-height: 16px;">lock</mat-icon>
		<span class="counter red"><span *ngIf="local_report_counting">~</span>{{ kvvm_reports }}</span>
	</button>
</div>
